.dotsVertical {
  position: absolute;
  right: 26px;
  top: 80px;

  z-index: 100;

  display: flex;
  width: 280px;
  flex-direction: column;

  border-radius: 8px;
  border: 1px solid $color-gray-200;
  background: $color-white;
  overflow: hidden;

  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  &-item {
    display: flex;
    padding: 10px 10px 10px 8px;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    color: $color-gray-900;

    @extend .text-md;
    @extend .font-medium;

    &:hover {
      background-color: $color-gray-200;
    }
  }
}