.eventReviewBlockSmall {
  width: 100%;
  min-width: 270px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid $color-gray-300;
  background: #FFF;
  box-sizing: border-box;
  flex-direction: column;
  height: 345px;

  &-footer {
    width: 125px;
  }

  &-icon {
    width: 100%;
    height: 100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    position: relative;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    padding: 24px 0 24px 24px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &-title {
        color: $color-gray-700;

        @extend .text-md;
        @extend .font-semibold;
      }

      &-description {
        color: $color-gray-700;

        @extend .text-sm;
        @extend .font-semibold;

        &-lite {
          color: $color-gray-600;
          font-weight: 400  !important;
        }
      }

      &-time {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;
      gap: 8px;
      
      &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        &-block {
          display: flex;
          gap: 8px;
          align-items: center;

          &-info {
            display: flex;
            gap: 8px;
            align-items: center;
          }
        }
      }
    }
  }
}