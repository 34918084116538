.addAttendees {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  z-index: 30;
  width: 100%;
  box-sizing: border-box;
  padding: 115px 0 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow: hidden;

  &_header {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 24px;

    &-title {
      color: $color-gray-900;
      @extend .text-md;
      @extend .font-semibold;
    }

    &-backButton {
      position: absolute;
      top: -10px;
      left: 10px;
    }
  }

  &_main {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    height: 100%;
  }

  &-selected {
    background: $color-primary-25;
  }
}