.horizontalTabs {
  display: flex;
  width: 542px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: $color-gray-50;
  z-index: 30;
  position: relative;

  &-withIllumination {
    gap: 0;
  }

  &-underline {
    align-items: flex-start;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    padding: 0;
    border-bottom: 1px solid $color-gray-200;
    background: inherit;
    justify-content: flex-start;

    border-radius: 0;
  }

  &-tab {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    position: relative;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    color: $color-gray-500;
    width: 100%;
    cursor: pointer;
    @extend .text-sm;
    @extend .font-semibold;

    &-notification {
      text-align: center;
      width: 24px;
      height: 24px;
      padding: 2px 8px 2px 8px;
      border-radius: 16px;
      box-sizing: border-box;
      border: 1px solid $color-primary-200;
      background-color: $color-primary-50;
      display: flex;
      align-items: center;
      justify-content: center;

      color: $color-primary-700;
      @extend .text-sm;
      @extend .font-medium;
    }

    &-underline {
      display: flex;
      padding: 1px 4px 11px 4px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 36px;
      border-radius: 0;
      width: fit-content;

      &-withIllumination {
        height: 48px;
        box-sizing: border-box;
        padding: 0;
        width: 50%;
      }

      &-selected {
        color: $color-primary-700 !important;
        @extend .text-sm;
        @extend .font-semibold;

        &::after {
          content: '';
          width: 100%;
          position: absolute;
          height: 2px;
          background-color: $color-primary-700;
          bottom: -2px;
          z-index: 100;
          opacity: 1;
        }

        &-withIllumination {
          background-color: $color-primary-25;

          &-first {
            border-top-left-radius: 12px;
          }

          &-last {
            border-top-right-radius: 12px;
          }
        }
      }
    }

    &-selected {
      background: $color-primary-50;
      color: $color-primary-700;
      @extend .text-sm;
      @extend .font-semibold;
    }
  }
}