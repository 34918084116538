.selectRestaurant {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: $color-white;
  padding: 0 0 0 24px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;

  &-withoutPadding {
    padding: 0;
  }

  @media (min-width: 768px) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &-main {
    overflow: hidden;
    height: calc(100% - 110px);

    &-withoutFooter {
      height: 100%;
    }
    
    &-withMaxWidth {
      padding-left: 24px;
      width: 35%;
      max-width: 35%;
    }

    &-header {
      display: flex;
      padding: 24px 24px 20px 0;
      flex-direction: row;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      @media (min-width: 768px) {
        flex-direction: column;
      }

      &-circles {
        position: absolute;
        left: 9px;
        top: -11px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: relative;
        z-index: 1;
        width: 100%;

        &-text {
          color: $color-gray-900;

          @extend .xs;
          @extend .font-semibold;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      padding-right: 20px;
      padding-left: 20px;
      height: 84%;

      @media (min-width) {
        padding-left: 0;
      }

      &-backButton {
        background-color: $color-gray-50;
        height: 40px;
        width: 40px;
        min-height: 40px;
        min-width: 40px;
        border-radius: 50%;
        padding: 8px;
        box-sizing: border-box;
        cursor: pointer;
      }

      @media (min-width: 768px) {
        height: 73%;
      }

      &-fullHeight {
        height: 100%;
      }

      &-location {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $color-gray-200;
        width: fit-content;
      }

      &-filters {
        display: flex;
        align-items: center;
        gap: 48px;
        width: 100%;
        align-self: stretch;
      }

      &-restaurants {
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: calc(100% - 20px);
        // height: calc(100% - 50px);
        max-height: calc(100% - 209px);

        &::-webkit-scrollbar {
          display: none
        }

        &-divider {
          width: 100%;
          height: 2px;
          background-color: $color-gray-200;
        }
      }
    };
  }

  &-footer {
    // position: absolute;
    // bottom: 0;
    // right: 0;
    position: relative;
    z-index: 35;
    box-shadow: 0px 75px 150px 0px #34405424;
    background-color: $color-white;
    display: flex;
    justify-content: end;
    padding: 32px 32px 32px 0;
    width: 100%;
    box-sizing: border-box;

    &-attendee {
      padding: 12px 24px 12px 0;
    }
  }

  &-noResultsFoundImg {
    width: 80px;
    height: 80px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-sizing: border-box;
    background: $color-gray-50;

    & img {
      width: 40px;
      height: 40px;
    }
  }
}

.addNewRestaurantBar {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  background-color: $color-primary-950;
  position: absolute;
  top: 72%;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  text-wrap: nowrap;
  color: $color-primary-25;
  cursor: pointer;

  @extend .text-md, .font-bold;

  @media (min-width: 768px) {
    top: 79%;
  }
}