.timePicker {
  position: absolute;
  display: inline-block;
  height: 320px;
  width: 100%;
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: 8px;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  box-sizing: border-box;
  padding: 4px 4px;
  z-index: 31;

  &-zone {
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    width: 290px;
  }

  &-lg {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }
  }

  &-heightSm {
    height: 50%;
    min-height: 180px;
  }

  &-heightXs {
    height: 25%;
    min-height: 90px;
  }

  &-heightAuto {
    height: auto;
  }

  &-fullHeight {
    height: 100%;
  }

  &-timeContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;

    &::-webkit-scrollbar {
        width: 8px;
      }
    
      &::-webkit-scrollbar-track {
        background: $color-white; 
        border: none;
      }
    
      &::-webkit-scrollbar-thumb {
        background: $color-gray-200;
        border-radius: 8px;
        height: 50%;
      }

    &-info {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 8px;
      width: 100%;
    }

    &-time {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 6px 0 10px;
      &:hover {
        border-radius: 6px;
        background: $color-gray-50;
      }

      & img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &-time {
    display: flex;
    border-radius: 6px;
    padding: 10px 0;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    color: $color-gray-900;
    white-space: nowrap;
    cursor: pointer;
    @extend .text-md;
    @extend .font-medium;

    &-supportText {
      color: $color-gray-600;
      white-space: nowrap;
      @extend .font-regular;
    }

    &-selected {
      border-radius: 6px;
      background: $color-gray-50;
    }

    &-disabled {
      pointer-events: none;
      color: $color-gray-300;
    }
  }
}

.time-container {
  width: 100%;
}