.contactBlock {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  min-width: 280px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
  background: $color-gray-50;
  width: 100%;
  border-radius: 12px;

  &-white {
    background-color: $color-white;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    &-title {
      color: $color-gray-900;

      @extend .text-lg;
      @extend .font-semibold;
    }

    &-description {
      color: $color-gray-600;

      @extend .text-md;
      @extend .font-regular;
    }
  }
}