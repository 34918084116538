.text-xl {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-style: normal;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-style: normal;
}

.text-md {
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-style: normal;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.xl2 {
  font-size: 4.5rem;
  line-height: 5.625rem;
  font-style: normal;
  letter-spacing: -2%;
}

.xl {
  font-size: 3.75rem;
  line-height: 4.5rem;
  font-style: normal;
  letter-spacing: -2%;
}

.lg {
  font-size: 3rem;
  line-height: 3.75rem;
  font-style: normal;
  letter-spacing: -2%;
}

.md {
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-style: normal;
  letter-spacing: -2%;
}

.sm {
  font-size: 1.875rem;
  line-height: 2.375rem;
  font-style: normal;
}

.xs {
  font-size: 1.5rem;
  line-height: 2rem;
  font-style: normal;
}