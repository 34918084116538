.reviewFile {
  display: flex;
  padding: 16px;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid $color-gray-200;
  background: $color-white;

  &-name {
    color: $color-primary-600;

    @extend .text-sm;
    @extend .font-medium;
  }
}