.modalCreateEventLogo {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &-label {
    color: $color-gray-700;

    @extend .text-sm;
    @extend .font-medium;
  }

  &-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: max-content;
    justify-content: space-between;
    align-items: center;
  }

  &-color {
    width: 32px;
    height: 32px;
    border-radius: 20px;
    border: 3px solid $color-white;
    cursor: pointer;

    &-error {
      border-color: $color-error-500;

      &-selected {
        background-color: $color-error-500;
      }
    }

    &-warning {
      border-color: $color-warning-500;

      &-selected {
        background-color: $color-warning-500;
      }
    }

    &-yellow {
      border-color: $color-yellow-400;

      &-selected {
        background-color: $color-yellow-400;
      }
    }

    &-success {
      border-color: $color-success-500;

      &-selected {
        background-color: $color-success-500;
      }
    }

    &-primary {
      border-color: $color-primary-600;

      &-selected {
        background-color: $color-primary-600;
      }
    }

    &-purple {
      border-color: $color-purple-500;

      &-selected {
        background-color: $color-purple-500;
      }
    }

    &-blue {
      border-color: $color-blue-500;

      &-selected {
        background-color: $color-blue-500;
      }
    }
  }
}