.bulletList {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-bullet {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5px;
    height: 156px;
    margin-bottom: 1.5px;

    &-small {
      height: 136px;
    }

    &-last {
      height: 16px;
    }

    &-circle {
      background: $color-primary-600;
      border-radius: 50%;
      width: 8px;
      height: 8px;

      &-container {
        background: $color-white;
        border: 1px solid $color-primary-600;
        padding: 3px;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-line {
      height: 100%;
      width: 1px;
      border-left: 1px dashed $color-primary-200;
      margin-left: 1px;
    }
  }
}