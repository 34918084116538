.eventDetailsWelcomePage {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;

  &-card {
    width: 100%;
    border: 1px solid $color-gray-200;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    gap: 24px;
    align-items: center;
    box-sizing: border-box;

    &-title {
      color: $color-gray-900;
      @extend .text-lg;
      @extend .font-semibold;
    }

    &-description {
        color: $color-gray-600;
        @extend .text-sm;
        @extend .font-regular;
      }
  }

  &-thingsTodo {
    &-text {
      color: $color-gray-900;
      @extend .text-lg;
      @extend .font-semibold;
    }

    &-count {
      background-color: $color-gray-50;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      border: 1px solid $color-gray-200;
      display: flex;
      justify-content: center;
      align-items: center;

      &-text {
        color: $color-gray-700;
        @extend .text-xs;
        @extend .font-medium;
      }
    }

    &-todo {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color-gray-200;
      box-sizing: border-box;
      padding: 8px 4px;

      &:hover {
        border-radius: 8px;
        border: none;
        background-color: $color-gray-50;
      }

      &-circle {
        width: 8px;
        min-width: 8px;
        height: 8px;
        min-height: 8px;
        border-radius: 50%;
        background-color: $color-warning-500;
        
        &-blue {
          background-color: $color-primary-500;
        }
      }

      &-message {
        padding: 3px 8px 3px 6px;
        gap: 3px;
        border-radius: 6px;
        background: $color-warning-50;
        border: 1px solid $color-warning-300;
        display: flex;
        align-items: center;
        color: $color-gray-700;

        @extend .text-xs;
        @extend .font-medium;

        &-blue {
          border-color: $color-primary-300;
          color: $color-primary-950;
          background: $color-primary-25;
        }
      }
    }
  }

  &-userRSVPImage {
    height: 16px;
    width: 16px;
  }
}