.toggle {
  width: 44px;
  height: 24px;
  padding: 2px;
  border-radius: 12px;
  background: $color-gray-200;
  display: flex;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: justify-content 5s ease-in-out;

  &-circle {
    width: 20px;
    height: 20px;
    background: $color-white;
    box-shadow: 0px 1px 3px 0px #1018281A;
    border-radius: 50%;
  }

  &-on {
    justify-content: end;
    background-color: $color-primary-600;
  }
}