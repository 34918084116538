.userImagePlaceholder {
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background-color: $color-gray-200;
  
  &-wrapper {
    position: relative;
  }

  &-text {
    color: $color-gray-600;
    @extend .text-md;
    @extend .font-semibold;

    &-verified {
      color: $color-primary-600;
    }
  }

  &-verified {
    background-color: $color-primary-100;
  }

  &-verifiedTick {
    position: absolute;
    top: 26px;
    right: 0px;
  }
}