.location {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  z-index: 200;
  width: 100%;
  box-sizing: border-box;
  padding: 32px 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  &_header {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &-title {
      color: $color-gray-900;
      @extend .text-md;
      @extend .font-semibold;
    }

    &-backButton {
      position: absolute;
      top: -10px;
      left: -16px;
    }
  }

  &_main {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    height: 100%;
  }

  &-action {
    padding: 5px 0;
  }
}