.progressBar {
  position: relative;
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 16px;
  border-radius: 8px;

  &-primary {
    background: $color-primary-600;
  }

  &-default {
    background: $color-gray-50;
  }

  &-dark {
    background: $color-gray-800;
  }

  &-close {
    position: absolute;
    right: 8px;
    top: 12px;
  }

  &-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @extend .text-sm;

    &-title {
      @extend .font-semibold;

      &-primary {
        color: $color-white;
      }

      &-default {
        color: $color-gray-900;
      }
    }

    &-helpText {
      @extend .font-regular;

      &-primary {
        color: $color-primary-200;
      }

      &-default {
        color: $color-gray-600;
      }

      &-dark {
        color: $color-gray-200;
      }
    }
  }

  &-progress {
    position: relative;
    display: inline-block;
    border-radius: 4px;
    width: 100%;
    height: 8px;

    &-percents {
      color: $color-gray-700;

      @extend .text-sm;
      @extend .font-medium;
    }

    &-primary {
      background: $color-primary-500;
    }

    &-default {
      background: $color-gray-200;
    }

    &-dark {
      background: $color-gray-600;
    }

    &-withColor {
      position: absolute;
      top: 0;
      background: $color-primary-100;
      display: inline-block;
      height: 8px;
      border-radius: 4px;
      width: 0%;
      max-width: 100%;
      // transition: width 0.3s;

      &-primary {
        background: $color-primary-100;
      }

      &-default {
        background: $color-primary-600;
      }

      &-dark {
        background: $color-gray-100;
      }
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

  &-button {
    background-color: inherit;
    border: none;
    cursor: pointer;
    @extend .text-sm;
    @extend .font-semibold;

    &-dismiss {
      &-primary {
        color: $color-primary-200;
      }

      &-default {
        color: $color-gray-600;
      }

      &-dark {
        color: $color-gray-300;
      }
    }

    &-upgrade {
      &-primary {
        color: $color-white;
      }

      &-default {
        color: $color-primary-700;
      }
    }
  }
}