$color-white: #FFFFFF;
$color-black: #000000;

$color-gray-25: #FCFCFD;
$color-gray-50: #F9FAFB;
$color-gray-100: #F2F4F7;
$color-gray-200: #EAECF0;
$color-gray-300: #D0D5DD;
$color-gray-400: #98A2B3;
$color-gray-500: #667085;
$color-gray-600: #475467;
$color-gray-700: #344054;
$color-gray-800: #1D2939;
$color-gray-900: #101828;
$color-gray-950: #0C111D;

$color-primary-25: #F5F8FF;
$color-primary-50: #EEF4FF;
$color-primary-100: #E0EAFF;
$color-primary-200: #C7D7FE;
$color-primary-300: #A4BCFD;
$color-primary-400: #8098F9;
$color-primary-500: #6172F3;
$color-primary-600: #444CE7;
$color-primary-700: #3538CD;
$color-primary-800: #2D31A6;
$color-primary-900: #2D3282;
$color-primary-950: #1F235B;

$color-error-25: #FFFBFA;
$color-error-50: #FEF3F2;
$color-error-100: #FEE4E2;
$color-error-200: #FECDCA;
$color-error-300: #FDA29B;
$color-error-400: #F97066;
$color-error-500: #F04438;
$color-error-600: #D92D20;
$color-error-700: #B42318;
$color-error-800: #912018;
$color-error-900: #7A271A;
$color-error-950: #55160C;

$color-warning-25: #FFFCF5;
$color-warning-50: #FFFAEB;
$color-warning-100: #FEF0C7;
$color-warning-200: #FEDF89;
$color-warning-300: #FEC84B;
$color-warning-400: #FDB022;
$color-warning-500: #F79009;
$color-warning-600: #DC6803;
$color-warning-700: #B54708;
$color-warning-800: #93370D;
$color-warning-900: #7A2E0E;
$color-warning-950: #4E1D09;

$color-success-25: #F6FEF9;
$color-success-50: #ECFDF3;
$color-success-100: #DCFAE6;
$color-success-200: #ABEFC6;
$color-success-300: #75E0A7;
$color-success-400: #47CD89;
$color-success-500: #17B26A;
$color-success-600: #079455;
$color-success-700: #067647;
$color-success-800: #085D3A;
$color-success-900: #074D31;
$color-success-950: #053321;

$color-success-25: #F6FEF9;
$color-success-50: #ECFDF3;
$color-success-100: #DCFAE6;
$color-success-200: #ABEFC6;
$color-success-300: #75E0A7;
$color-success-400: #47CD89;
$color-success-500: #17B26A;
$color-success-600: #079455;
$color-success-700: #067647;
$color-success-800: #085D3A;
$color-success-900: #074D31;
$color-success-950: #053321;

$color-grayBlue-25: #FCFCFD;
$color-grayBlue-50: #F8F9FC;
$color-grayBlue-100: #EAECF5;
$color-grayBlue-200: #D5D9EB;
$color-grayBlue-300: #B3B8DB;
$color-grayBlue-400: #717BBC;
$color-grayBlue-500: #4E5BA6;
$color-grayBlue-600: #3E4784;
$color-grayBlue-700: #363F72;
$color-grayBlue-800: #293056;
$color-grayBlue-900: #101323;
$color-grayBlue-950: #0D0F1C;

$color-grayCool-25: #FCFCFD;
$color-grayCool-50: #F9F9FB;
$color-grayCool-100: #EFF1F5;
$color-grayCool-200: #DCDFEA;
$color-grayCool-300: #B9C0D4;
$color-grayCool-400: #7D89B0;
$color-grayCool-500: #5D6B98;
$color-grayCool-600: #4A5578;
$color-grayCool-700: #404968;
$color-grayCool-800: #30374F;
$color-grayCool-900: #111322;
$color-grayCool-950: #0E101B;

$color-grayModern-25: #FCFCFD;
$color-grayModern-50: #F8FAFC;
$color-grayModern-100: #EEF2F6;
$color-grayModern-200: #E3E8EF;
$color-grayModern-300: #CDD5DF;
$color-grayModern-400: #9AA4B2;
$color-grayModern-500: #697586;
$color-grayModern-600: #4B5565;
$color-grayModern-700: #364152;
$color-grayModern-800: #202939;
$color-grayModern-900: #121926;
$color-grayModern-950: #0D121C;

$color-grayNeutral-25: #FCFCFD;
$color-grayNeutral-50: #F9FAFB;
$color-grayNeutral-100: #F3F4F6;
$color-grayNeutral-200: #E5E7EB;
$color-grayNeutral-300: #D2D6DB;
$color-grayNeutral-400: #9DA4AE;
$color-grayNeutral-500: #6C737F;
$color-grayNeutral-600: #4D5761;
$color-grayNeutral-700: #384250;
$color-grayNeutral-800: #1F2A37;
$color-grayNeutral-900: #111927;
$color-grayNeutral-950: #0D121C;

$color-grayIron-25: #FCFCFC;
$color-grayIron-50: #FAFAFA;
$color-grayIron-100: #F4F4F5;
$color-grayIron-200: #E4E4E7;
$color-grayIron-300: #D1D1D6;
$color-grayIron-400: #A0A0AB;
$color-grayIron-500: #70707B;
$color-grayIron-600: #51525C;
$color-grayIron-700: #3F3F46;
$color-grayIron-800: #26272B;
$color-grayIron-900: #18181B;
$color-grayIron-950: #131316;

$color-grayWarm-25: #FDFDFC;
$color-grayWarm-50: #FAFAF9;
$color-grayWarm-100: #F5F5F4;
$color-grayWarm-200: #E7E5E4;
$color-grayWarm-300: #D7D3D0;
$color-grayWarm-400: #A9A29D;
$color-grayWarm-500: #79716B;
$color-grayWarm-600: #57534E;
$color-grayWarm-700: #44403C;
$color-grayWarm-800: #292524;
$color-grayWarm-900: #1C1917;
$color-grayWarm-950: #171412;

$color-moss-25: #FAFDF7;
$color-moss-50: #F5FBEE;
$color-moss-100: #E6F4D7;
$color-moss-200: #CEEAB0;
$color-moss-300: #ACDC79;
$color-moss-400: #86CB3C;
$color-moss-500: #669F2A;
$color-moss-600: #4F7A21;
$color-moss-700: #3F621A;
$color-moss-800: #335015;
$color-moss-900: #2B4212;
$color-moss-950: #1A280B;

$color-greenLight-25: #FAFEF5;
$color-greenLight-50: #F3FEE7;
$color-greenLight-100: #E4FBCC;
$color-greenLight-200: #D0F8AB;
$color-greenLight-300: #A6EF67;
$color-greenLight-400: #85E13A;
$color-greenLight-500: #66C61C;
$color-greenLight-600: #4CA30D;
$color-greenLight-700: #3B7C0F;
$color-greenLight-800: #326212;
$color-greenLight-900: #2B5314;
$color-greenLight-950: #15290A;

$color-green-25: #F6FEF9;
$color-green-50: #EDFCF2;
$color-green-100: #D3F8DF;
$color-green-200: #AAF0C4;
$color-green-300: #73E2A3;
$color-green-400: #3CCB7F;
$color-green-500: #16B364;
$color-green-600: #099250;
$color-green-700: #087443;
$color-green-800: #095C37;
$color-green-900: #084C2E;
$color-green-950: #052E1C;

$color-teal-25: #F6FEFC;
$color-teal-50: #F0FDF9;
$color-teal-100: #CCFBEF;
$color-teal-200: #99F6E0;
$color-teal-300: #5FE9D0;
$color-teal-400: #2ED3B7;
$color-teal-500: #15B79E;
$color-teal-600: #0E9384;
$color-teal-700: #107569;
$color-teal-800: #125D56;
$color-teal-900: #134E48;
$color-teal-950: #0A2926;

$color-cyan-25: #F5FEFF;
$color-cyan-50: #ECFDFF;
$color-cyan-100: #CFF9FE;
$color-cyan-200: #A5F0FC;
$color-cyan-300: #67E3F9;
$color-cyan-400: #22CCEE;
$color-cyan-500: #06AED4;
$color-cyan-600: #088AB2;
$color-cyan-700: #0E7090;
$color-cyan-800: #155B75;
$color-cyan-900: #164C63;
$color-cyan-950: #0D2D3A;

$color-blueLight-25: #F5FBFF;
$color-blueLight-50: #F0F9FF;
$color-blueLight-100: #E0F2FE;
$color-blueLight-200: #B9E6FE;
$color-blueLight-300: #7CD4FD;
$color-blueLight-400: #36BFFA;
$color-blueLight-500: #0BA5EC;
$color-blueLight-600: #0086C9;
$color-blueLight-700: #026AA2;
$color-blueLight-800: #065986;
$color-blueLight-900: #0B4A6F;
$color-blueLight-950: #062C41;

$color-blue-25: #F5FAFF;
$color-blue-50: #EFF8FF;
$color-blue-100: #D1E9FF;
$color-blue-200: #B2DDFF;
$color-blue-300: #84CAFF;
$color-blue-400: #53B1FD;
$color-blue-500: #2E90FA;
$color-blue-600: #1570EF;
$color-blue-700: #175CD3;
$color-blue-800: #1849A9;
$color-blue-900: #194185;
$color-blue-950: #102A56;

$color-blueDark-25: #F5F8FF;
$color-blueDark-50: #EFF4FF;
$color-blueDark-100: #D1E0FF;
$color-blueDark-200: #B2CCFF;
$color-blueDark-300: #84ADFF;
$color-blueDark-400: #528BFF;
$color-blueDark-500: #2970FF;
$color-blueDark-600: #155EEF;
$color-blueDark-700: #004EEB;
$color-blueDark-800: #0040C1;
$color-blueDark-900: #00359E;
$color-blueDark-950: #002266;

$color-indigo-25: #F5F8FF;
$color-indigo-50: #EEF4FF;
$color-indigo-100: #E0EAFF;
$color-indigo-200: #C7D7FE;
$color-indigo-300: #A4BCFD;
$color-indigo-400: #8098F9;
$color-indigo-500: #6172F3;
$color-indigo-600: #444CE7;
$color-indigo-700: #3538CD;
$color-indigo-800: #2D31A6;
$color-indigo-900: #2D3282;
$color-indigo-950: #1F235B;

$color-violet-25: #FBFAFF;
$color-violet-50: #F5F3FF;
$color-violet-100: #ECE9FE;
$color-violet-200: #DDD6FE;
$color-violet-300: #C3B5FD;
$color-violet-400: #A48AFB;
$color-violet-500: #875BF7;
$color-violet-600: #7839EE;
$color-violet-700: #6927DA;
$color-violet-800: #5720B7;
$color-violet-900: #491C96;
$color-violet-950: #2E125E;

$color-purple-25: #FAFAFF;
$color-purple-50: #F4F3FF;
$color-purple-100: #EBE9FE;
$color-purple-200: #D9D6FE;
$color-purple-300: #BDB4FE;
$color-purple-400: #9B8AFB;
$color-purple-500: #7A5AF8;
$color-purple-600: #6938EF;
$color-purple-700: #5925DC;
$color-purple-800: #4A1FB8;
$color-purple-900: #3E1C96;
$color-purple-950: #27115F;

$color-fuchsia-25: #FEFAFF;
$color-fuchsia-50: #FDF4FF;
$color-fuchsia-100: #FBE8FF;
$color-fuchsia-200: #F6D0FE;
$color-fuchsia-300: #EEAAFD;
$color-fuchsia-400: #E478FA;
$color-fuchsia-500: #D444F1;
$color-fuchsia-600: #BA24D5;
$color-fuchsia-700: #9F1AB1;
$color-fuchsia-800: #821890;
$color-fuchsia-900: #6F1877;
$color-fuchsia-950: #47104C;

$color-pink-25: #FEF6FB;
$color-pink-50: #FDF2FA;
$color-pink-100: #FCE7F6;
$color-pink-200: #FCCEEE;
$color-pink-300: #FAA7E0;
$color-pink-400: #F670C7;
$color-pink-500: #EE46BC;
$color-pink-600: #DD2590;
$color-pink-700: #C11574;
$color-pink-800: #9E165F;
$color-pink-900: #851651;
$color-pink-950: #4E0D30;

$color-rose-25: #FFF5F6;
$color-rose-50: #FFF1F3;
$color-rose-100: #FFE4E8;
$color-rose-200: #FECDD6;
$color-rose-300: #FEA3B4;
$color-rose-400: #FD6F8E;
$color-rose-500: #F63D68;
$color-rose-600: #E31B54;
$color-rose-700: #C01048;
$color-rose-800: #A11043;
$color-rose-900: #89123E;
$color-rose-950: #510B24;

$color-orangeDark-25: #FFF9F5;
$color-orangeDark-50: #FFF4ED;
$color-orangeDark-100: #FFE6D5;
$color-orangeDark-200: #FFD6AE;
$color-orangeDark-300: #FF9C66;
$color-orangeDark-400: #FF692E;
$color-orangeDark-500: #FF4405;
$color-orangeDark-600: #E62E05;
$color-orangeDark-700: #BC1B06;
$color-orangeDark-800: #97180C;
$color-orangeDark-900: #771A0D;
$color-orangeDark-950: #57130A;

$color-orange-25: #FEFAF5;
$color-orange-50: #FEF6EE;
$color-orange-100: #FDEAD7;
$color-orange-200: #F9DBAF;
$color-orange-300: #F7B27A;
$color-orange-400: #F38744;
$color-orange-500: #EF6820;
$color-orange-600: #E04F16;
$color-orange-700: #B93815;
$color-orange-800: #932F19;
$color-orange-900: #772917;
$color-orange-950: #511C10;

$color-yellow-25: #FEFDF0;
$color-yellow-50: #FEFBE8;
$color-yellow-100: #FEF7C3;
$color-yellow-200: #FEEE95;
$color-yellow-300: #FDE272;
$color-yellow-400: #FAC515;
$color-yellow-500: #EAAA08;
$color-yellow-600: #CA8504;
$color-yellow-700: #A15C07;
$color-yellow-800: #854A0E;
$color-yellow-900: #713B12;
$color-yellow-950: #542C0D;