.checkBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $color-gray-300;
  background: $color-white;
  cursor: pointer;
  z-index: 150;

  &:hover {
    border: 1px solid $color-primary-600;
    background: $color-primary-100;
  }

  &:focus-within {
    border: 1px solid $color-primary-300;
    background: $color-white;
    box-shadow: 0px 0px 0px 4px #EAEBFF;
  }

  &-disabled {
    border: 1px solid $color-gray-300;
    background: $color-gray-100;
    cursor: not-allowed;

    &:hover {
      border: 1px solid $color-gray-300;
      background: $color-gray-100;
    }  
  }

  &-selected {
    border: 1px solid $color-primary-600;
    background: $color-primary-50;

    &:hover {
      border: 1px solid $color-primary-600;
      background: $color-primary-100;
    }
    
    &:focus-within {
      border: 1px solid $color-primary-300;
      background: $color-primary-50;
      box-shadow: 0px 0px 0px 4px #EAEBFF;
    }
    
    &-disabled {
      border: 1px solid $color-gray-300;
      background: $color-gray-100;
    }
  }
}