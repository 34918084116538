.eventsGallery {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  min-height: 245px;
  padding: 20px 12px;
  background-color: $color-white;
  border-radius: 12px;
  height: fit-content;
  max-height: 100%;

  &-minHeight410px {
    min-height: 410px;
  }

  &-controls {
    display: flex;
    align-items: center;
    gap: 12px;

    &-dots {
      max-width: 48px;
      width: fit-content;
      display: flex;
      gap: 12px;
      align-items: center;
      transition: translate 0.5s ease-out;

      &::-webkit-scrollbar {
        display: none;
      }

      &-dot {
        width: 8px;
        min-width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-gray-200;
        cursor: pointer;

        &-selected {
          background-color: $color-gray-600;
        }
      }
    }
  }

  &-title {
    color: $color-gray-900;

    @extend .text-lg;
    @extend .font-semibold;
  }

  &-count {
    padding: 2px 8px 2px 8px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid $color-gray-200;
    background: $color-gray-50;
    display: flex;
    align-items: center;
    justify-content: center;

    color: $color-gray-700;

    @extend .text-xs;
    @extend .font-medium;
  }

  &-events {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    box-sizing: border-box;
    transition: translate 0.6s ease-in-out;
    width: 100%;
  }
}

.noEventsFoundEventsGallery {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 8px 16px 8px;
  border-radius: 12px;
  border: 1px solid $color-gray-200;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-logo {
    width: 96px;
    height: 96px;
    padding: 32px;
    border-radius: 50%;  
    background: linear-gradient(180deg, #F9FAFB 0%, #EDF0F3 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    & img {
      width: 25.25px;
      height: 32px;
      rotate: (-12deg);
    }
  }

  &-title {
    color: $color-gray-900;
    
    @extend .text-md;
    @extend .font-semibold;
  }

  &-description {
    color: $color-gray-600;
    
    @extend .text-sm;
    @extend .font-regular;
  }
}