.restaurantMenu {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: $color-white;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;

  @media (min-width: 768px) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0;
  }

  &-main {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    background-color: $color-gray-50;
    padding: 24px 24px 169px 24px;

    @media (min-width: 768px) {
      height: 96%;
      flex-direction: row;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-customRest {
      width: 100%;
      margin: 10% auto 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 510px;
      align-items: center;

      &-restaurant {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 24px;
        gap: 16px;
        border-radius: 28px;
        border: 2px;
        border: 2px solid $color-gray-400;
        background: $color-white;
        width: fit-content;

        &-name {
          color: $color-gray-950;

          @extend .text-lg, .font-semibold;
        }

        &-location {
          color: $color-gray-600;
  
          @extend .text-lg, .font-semibold;
        }
      }

      &-description {
        color: $color-gray-950;
        text-align: center;

        @extend .text-lg, .font-semibold;
      }
    }

    &-infoWhenAttendeeOrders {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      &-banner {
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
        gap: 16px;
        border-radius: 16px;
        border: 4px solid $color-primary-600;
        background-color: $color-primary-100;

        &-title {
          color: $color-primary-950;

          @extend .sm, .font-bold;
        }

        &-text {
          color: $color-primary-950;

          @extend .text-md, .font-regular;
        }
      }

      &-togglerContainer {
        width: 100%;
        box-sizing: border-box;
        padding: 12px 8px 12px 8px;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid $color-gray-200;
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-counter {
          color: $color-gray-900;

          @extend .text-md, .font-regular;
        }
      }
    }

    &-menu {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
      box-sizing: border-box;

      @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &-footer {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
}

.categoryTabs {
  display: flex;
  gap: 12px;
  min-width: 140px;
  width: 100%;
  overflow-x: scroll;
  height: 120px;

  @media (min-width: 768px) {
    flex-direction: column;
    width: 140px;
    height: auto;
  }
  
  &::-webkit-scrollbar {
    display: none;
  }

  &-tab {
    width: auto;
    box-sizing: border-box;
    padding: 12px; 
    border-radius: 12px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    cursor: pointer;
    height: min-content;

    @media (min-width: 768px) {
      width: 140px;
      height: auto;
    }
    
    &-name {
      color: $color-gray-950;
      text-wrap: nowrap;

      @extend .text-xl;
      @extend .font-semibold;

      @media (min-width: 768px) {
        flex-direction: column;
        width: 140px;
        text-wrap: wrap;
      }

      &-selected {
        color: $color-white;
      }
    }

    &-description {
      color: $color-gray-950;

      @extend .text-xs;
      @extend .font-regular;

      &-selected {
        color: $color-white;
      }
    }

    &-selected {
      background-color: $color-primary-600;
    }
  }
}