.menuItem {
  width: 100%;
  border: 1px solid $color-gray-200;
  border-radius: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
  linear-gradient(0deg, #EAECF0, #EAECF0);

  @media (min-width: 768px) {
    // flex-direction: row;
    padding: 12px;
  }

  &-selected {
    border: 1px solid #444CE7;
  }

  &-image {
    width: 100%;
    min-width: 100%;
    min-height: 144px;
    height: 144px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    @media (min-width: 768px) {
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
    }
  }

  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px;
    gap: 12px;

    @media (min-width: 768px) {
      padding: 0;
    }

    &-name {
      color: $color-gray-900;

      @extend .text-lg;
      @extend .font-medium;
    }

    &-description {
      color: $color-gray-600;

      @extend .text-md;
      @extend .font-regular;
    }

    
    &-price {
      color: $color-gray-900;

      @extend .text-xl;
      @extend .font-semibold;
    }

    &-count {
      color: $color-gray-900;

      @extend .text-md;
      @extend .font-semibold;
    }
  }
}