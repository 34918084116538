.customScheduleDay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3px 4px;
  flex-direction: column;
  gap: 18px;
  border: 1px solid rgba(218, 220, 224, 0.60);
  background: $color-white;
  width: 100%;
  height: 100%;
  min-height: 116px;
  box-sizing: border-box;
  
  &-showAll {
    height: min-content;
    position: absolute;
    z-index: 100;
    width: 130%;
    transform: translate(-13%, -12px);
    border-radius: 4px;
    min-height: 110%;
  }

  &-container {
    position: relative;
  }

  &-today {
    background-color: $color-primary-100;
  }

  &-disabled {
    background: #f2f2f2;
    pointer-events: none;
  }

  &-selected {
    background: $color-primary-100;
  }

  &-date {
    color: #333;
    padding: 10px;

    @extend .text-md;
    @extend .font-medium;
  }

  &-event {
    display: flex;
    padding: 2px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    &-name {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      max-width: 100%;

      &-circle {
        min-width: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-primary-200;

        &-success {
          background-color: $color-success-200;
        }
      
        &-yellow {
          background-color: $color-yellow-200;
        }
      
        &-warning {
          background-color: $color-warning-200;
        }
      
        &-error {
          background-color: $color-error-200;
        }
      
        &-purple {
          background-color: $color-purple-200;
        }
      
        &-blueLight {
          background-color: $color-blueLight-200;
        }
      }

      &-name {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-primary-900;

        &-success {
          color: $color-success-900;
        }
      
        &-yellow {
          color: $color-yellow-900;
        }
      
        &-warning {
          color: $color-warning-900;
        }
      
        &-error {
          color: $color-error-900;
        }
      
        &-purple {
          color: $color-purple-900;
        }
      
        &-blueLight {
          color: $color-blueLight-900;
        }

        @extend .text-md;
        @extend .font-semibold;
      }
    }

    &-moreEvents {
      color: $color-primary-600;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      padding: 0 4px;

      &:hover {
        background-color: $color-gray-100;
        border-radius: 4px;
      }
    }

    &-time {
      color: #333;

      @extend .text-md;
      @extend .font-medium;
    }
  }
}

.daySchedule {
  border: 2px solid rgba(218, 220, 224, 0.6);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-sizing: border-box;
  overflow: hidden;
  border-top: none;

  &-day {
    height: 50px;
    width: 100%;
    background-color: $color-white;
    border-bottom: 1px solid #DADCE0;
    box-sizing: border-box;

    &:last-of-type {
      border-bottom: none;
    }

    &-event {
      background-color: $color-error-50;
      border-radius: 4px;
      height: 44px;
      width: 100%;
      padding: 6px;
      box-sizing: border-box;

      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      &-name {
        color: $color-error-800;

        @extend .text-sm;
        @extend .font-semibold;
      }

      &-circle {
        width: 8px;
        height: 8px;
        background-color: $color-error-600;
        border-radius: 50%;
      }

      &-description {
        color: #333;

        @extend .text-sm;
        @extend .font-regular;
      }
    }

    &-events {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      gap: 6px;
      padding: 4.5px 8px;
      box-sizing: border-box;
    }
  }

  &-hour {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    
    &:first-of-type {
      margin-top: 69px;
    }
  }
}