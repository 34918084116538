.terms {
  background: $color-white;
  box-sizing: border-box;

  &_header {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 16px 40px;
    background-color: $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid $color-gray-200;
  }

  &_footer {
    padding: 28px 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: $color-white;
    position: sticky;
    bottom: 0;
    border-top: 1px solid $color-gray-200;
  }

  &_main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 64px;
    max-width: 65%;
    margin: 12px auto;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 24px;
    }
  }
}