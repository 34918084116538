.accountCreated {
  width: 100%;
  height: 100%;
  background-color: $color-white;

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $color-gray-200;
    background-color: $color-white;
    padding: 12px 0;

    @media (min-width: 768px) {
      padding-left: 110px;
      justify-content: center;
      justify-content: flex-start;
    }

    &-logo {
      width: 200px;
      height: 48px;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
      height: 100%;
      border-bottom-left-radius: 625px;
      border-bottom-right-radius: 625px;
    }

    @media (min-width: 768px) {
      width: 768px;
      margin-left: auto;
      margin-right: auto;
    }

    &-content {
      margin-top: 32px;
      display: flex;
      width: 90%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      z-index: 30;

      @media (min-width: 768px) {
        width: 360px;
      }

      &-title {
        color: $color-gray-900;
       text-align: center;

        @extend .xs;
        @extend .font-semibold;
      }

      &-description {
        color: $color-gray-600;
        text-align: center;
        white-space: nowrap;

        @extend .text-md;
        @extend .font-regular;
      }

      &-email {
        color: $color-gray-600;
        text-align: center;
        white-space: nowrap;

        @extend .text-md;
        @extend .font-medium;
      }
    }
  }
}