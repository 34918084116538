.button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background: none;
  box-sizing: border-box;
  cursor: pointer;

  &-sm {
    padding: 8px 14px;
    gap: 8px;
  }

  &-md {
    padding: 10px 16px;
    gap: 8px;
  }

  &-lg {
    padding: 10px 18px;
    gap: 8px;
  }

  &-xl {
    padding: 12px 20px;
    gap: 8px;
  }

  &-2xl {
    padding: 16px 28px;
    gap: 12px;
  }

  &-contentCenter {
    justify-content: center;
  }

  &-fullWidth {
    width: 100% !important;
  }

  &-fullHeight {
    height: 100%;
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-wrap: nowrap;
  }

  &_primary {
    background-color: $color-primary-600;
    border-color: $color-primary-600;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:focus {
      box-shadow: 0px 0px 0px 4px #E0EAFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  
    &:hover {
      background-color: $color-primary-700;
      border-color: $color-primary-700;
    }
  
    &:disabled {
      background-color: $color-primary-200;
      border-color: $color-primary-200;
      cursor: no-drop;
    }
  }

  &_secondaryGray {
    background-color: $color-white;
    border-color: $color-gray-300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:focus {
      box-shadow: 0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  
    &:hover {
      background-color: $color-gray-50;
      border-color: $color-gray-300;
    }
  
    &:disabled {
      background-color: $color-white;
      border-color: $color-gray-200;
      cursor: no-drop;
    }
  }

  &_secondaryColor {
    background-color: $color-primary-50;
    border-color: $color-primary-200;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:focus {
      box-shadow: 0px 0px 0px 4px #E0EAFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  
    &:hover {
      background-color: $color-primary-100;
      border-color: $color-primary-200;
    }
  
    &:disabled {
      background-color: $color-primary-25;
      border-color: $color-primary-25;
      cursor: no-drop;
    }
  }

  &_tertiaryGray {
    &:hover {
      background-color: $color-gray-50;
      border-radius: 8px;
    }

    &-hoverDark {
      &:hover {
        background-color: $color-primary-700;
        border-radius: 8px;
      }
    }

    &:disabled {
      cursor: no-drop;
    }
  }

  &_tertiaryColor {
    &:hover {
      background-color: $color-primary-50;
      border-radius: 8px;
    }

    &:disabled {
      cursor: no-drop;
    }
  }

  &_error {
    background-color: $color-error-600;
    border-color: $color-error-600;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 80px !important;
    padding: 8px 24px;

    &:focus {
      box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  
    &:hover {
      background-color: $color-error-700;
      border-color: $color-error-700;
    }
  
    &:disabled {
      background-color: $color-error-200;
      border-color: $color-error-200;
      cursor: no-drop;
    }
  }

  &_errorSecondaryGray {
    background-color: $color-white;
    border-color: $color-error-300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 80px !important;
    padding: 8px 24px;

    &:hover {
      background-color: $color-error-50;
      border-color: $color-error-300;
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  
    &:disabled {
      background-color: $color-white;
      border-color: $color-error-200;
      cursor: no-drop;
    }
  }

  &_errorSecondaryColor {
    background-color: $color-error-50;
    border-color: $color-error-200;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:hover {
      background-color: $color-error-100;
      border-color: $color-error-200;
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  
    &:disabled {
      background-color: $color-error-25;
      border-color: $color-error-25;
      cursor: no-drop;
    }
  }

  &_errorTertiaryGray {
    &:hover {
      background-color: $color-error-50;
      border-radius: 8px;
    }

    &:disabled {
      cursor: no-drop;
    }
  }

  &-newDesign {
    width: fit-content;

    &-gray {
      border-radius: 12px;
      border: 1px solid $color-gray-600;
      background-color: $color-gray-50;

      &-rounded {
        border-radius: 80px !important;
        padding: 8px 24px;
      }

      &-padding {
        padding: 24px 12px;
      }

      &:disabled {
        cursor: no-drop;
      }
    }

    &-primary {
      border: none;
      padding: 8px 24px;
      border-radius: 20px;
      transition: background-color 0.3s ease-in-out;
      background-color: $color-primary-600;  

      &-padding {
        padding: 24px 12px;
      }   

      &:hover {
        background-color: $color-primary-800;
      }

      &:disabled {
        cursor: no-drop;
      }
    }

    &-secondary {
      border: 2px solid $color-primary-600;
      padding: 8px 24px;
      border-radius: 20px;
      transition: background-color 0.3s ease-in-out;
      background-color: $color-white;
      box-sizing: border-box;
      color: $color-primary-600;

      &-padding {
        padding: 24px 12px;
      }

      &:hover {
        border: 2px solid $color-primary-800;
      }

      &:disabled {
        cursor: no-drop;
      }
    }

    &-terciary {
      border: 1px solid $color-gray-300;
      padding: 8px 24px;
      border-radius: 20px;
      transition: background-color 0.3s ease-in-out;
      background-color: $color-white;
      box-sizing: border-box;
      color: $color-primary-600;
      transition: border .3s;

      &-padding {
        padding: 24px 12px;
      }

      &:hover {
        border: 1px solid $color-gray-500;
      }

      &:disabled {
        cursor: no-drop;
      }
    }

    &-error {
      border: 1px solid $color-error-600;
      border-radius: 12px;
      background-color: $color-gray-50;
    }

    &:disabled {
      cursor: no-drop;
    }
  }

  &_close {
    border-radius: 8px;

    &-primary {
      &:hover {
        background-color: $color-primary-50;
      }

      &:focus {
        box-shadow: 0px 0px 0px 4px #EAEBFF;
      }

      &-blackTheme {
        &:hover {
          background-color: $color-primary-400;
        }
  
        &:focus {
          box-shadow: 0px 0px 0px 4px #444CE7;
        }
      }
    }
  
    &-gray {
      &:hover {
        background-color: $color-gray-100;
      }

      &:focus {
        background-color: $color-gray-100;
      }

      &-blackTheme {
        &:hover {
          background-color: $color-gray-400;
        }
  
        &:focus {
          box-shadow: 0px 0px 0px 4px #667085;
        }
      }
    }
  }

  &_square {
    &-sm {
      padding: 8px;
      gap: 8px;
    }
  
    &-md {
      padding: 10px;
      gap: 8px;
    }
  
    &-lg {
      padding: 12px;
      gap: 8px;
    }
  
    &-xl {
      padding: 14px;
      gap: 8px;
    }
  
    &-2xl {
      padding: 16px;
      gap: 8px;
    }
  }

  &_paddingNone {
    padding: 0;
  }
}

.hoverLight {
  &:hover {
    background-color: $color-gray-100;
  }
}

.textPrimary {
  color: $color-white
}

.textSecondaryGray {
  color: $color-gray-700;

  &:hover {
    color: $color-gray-800;
  }

  &:disabled {
    color: $color-gray-300;
  }
}

.textSecondaryColor {
  color: $color-primary-700;

  &:hover {
    color: $color-primary-800;
  }

  &:disabled {
    color: $color-primary-300;
  }
}

.textTertiaryGray {
  color: $color-gray-600;

  &:hover {
    color: $color-gray-700;
  }

  &:disabled {
    color: $color-gray-300;
  }
}

.textTertiaryColor {
  color: $color-primary-700;

  &:hover {
    color: $color-primary-800;
  }

  &:disabled {
    color: $color-gray-300;
  }
}

.textLinkGray {
  color: $color-gray-600;

  &:hover {
    color: $color-gray-700;
  }

  &:disabled {
    color: $color-gray-300;
  }
}

.textLinkColor {
  color: $color-primary-700;
  cursor: pointer;

  &:hover {
    color: $color-primary-800;
  }

  &:disabled {
    color: $color-gray-300;
    cursor: no-drop;
  }
}

.textError {
  color: $color-error-700;

  &:hover {
    color: $color-error-800;
  }

  &:disabled {
    color: $color-error-300;
  }
}

// .buttonCircle {
//   display: inline-block;
//   border-radius: 50%;
//   border: 2px solid;

//   &-base {
//     width: 16px;
//     height: 16px;
//   }

//   &-lg {
//     width: 21px;
//     height: 21px;
//   }
// }

.buttonIsOnlineCircle {
  display: inline-block;
  border-radius: 50%;
  width: 8px;
  height: 8px;

  &_primary {
    background-color: $color-white;
  }

  &_success {
    background-color: $color-success-500;

    &-disabled {
      background-color: $color-gray-300;
    }
  }

  &_secondaryColor {
    &-disabled {
      background-color: $color-primary-300;
    }
  }

  &_error {
    &-disabled {
      background-color: $color-error-300;
    }
  }
}

.border {
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
}