.eventsEmptyPage {
  display: flex;
  padding: 70px 32px 0px 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  max-width: 840px;
  margin: 145px auto 0;

  &-icon {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    padding: 12px 15px;
    border-radius: 50%;
    background-color: $color-primary-100;

    & img {
      width: 24px;
      height: 24px;
    }
  }

  &-info {
    display: flex;
    max-width: 80%;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    &-title {
      color: $color-gray-900;

      @extend .xl;
      @extend .font-semibold;
    }

    &-description {
      color: $color-gray-600;
      text-align: center;

      @extend .text-xl;
      @extend .font-regular;
    }
  }

  &-actions {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
      height: 56%;
    }
  }
}
