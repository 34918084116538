html {
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  height: 100%;
}

#root {
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
textarea,
input ,
body,
ul,
li,
table, 
thead,
tbody,
tr,
th,
td {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

* {
  font-family: 'Inter', sans-serif;
}

.modal-main-container{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;

  &-withBackdrop {
    backdrop-filter: blur(8px);
  }
}

form {
  &:focus-visible {
    outline: none;
  }
}