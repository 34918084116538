.eventReviewBlock {
  width: 100%;
  display: flex;
  padding: 12px 32px;
  align-items: center;
  gap: 24px;
  box-sizing: border-box;
  position: relative;

  border-radius: 12px;
  border: 1px solid $color-gray-300;

  &-title {
    display: flex;
    width: 90px;
    justify-content: space-between;
    gap: 8px;

    &-rectangle {
      width: 2px;
      border-radius: 40px;
      background: $color-gray-300;
    }

    &-text {

      &-title {
        color: $color-gray-700;
        @extend .text-md;
        @extend .font-semibold;
        white-space: nowrap;
      }

      &-description {
        color: $color-gray-600;
        @extend .text-sm;
        @extend .font-regular;
        white-space: nowrap;
      }

      &-day {
        color: $color-warning-600;
        @extend .text-lg;
        @extend .font-medium;
        white-space: nowrap;
      }

      &-number {
        color: $color-warning-600;
        @extend .md;
        @extend .font-bold;
        white-space: nowrap;
      }

      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
      }
    }
  }

  &-content {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    width: 100%;

    &-info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &-content {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }
  }
}