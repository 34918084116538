.alphaReleaseBanner {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 24px;
  gap: 12px;
  background-color: $color-gray-800;
  box-sizing: border-box;
  justify-content: space-between;

  @media (min-width: 768px) {
    width: calc(100% - 112px);
    position: absolute;
    top: 0;
    left: 112px;
  }

  &-title {
    color: $color-white;

    @extend .text-sm, .font-semibold;
  }

  &-description {
    color: $color-gray-25;

    @extend .text-sm, .font-regular;
  }
}
