.eventsPage {
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 75%;
    padding: 0 12px;

    @media (min-width: 768px) {
      margin: 0 auto;
      padding: unset;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      z-index: 30;
    }

    &-title {
      color: $color-gray-900 !important;

      @extend .font-semibold;
    }

    &-description {
      color: $color-gray-600 !important;

      @extend .text-md;
      @extend .font-regular;
    }

    &-addNewButton {
      display: flex;
      gap: 8px;

      &-text {
        color: $color-white !important;

        @extend .text-sm;
        @extend .font-semibold;
      }
    }
  }

  &-info {
    padding-left: 0;
    padding-right: 0;
  }

  &-toggle {
    @media (max-width: 767px) {
      position: fixed;
      bottom: 115px;
      z-index: 99;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
    }
  }

  &-content {
    padding-top: 145px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 65px;

    @media (min-width: 768px) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}