.alert {
  margin-top: 20px;
  background-color: $color-warning-25;
  z-index: 20;
  display: flex;
  align-items: center;
  border: 1px solid $color-warning-300;
  box-shadow: 0px 1px 2px 0px #1018280D;
  padding: 16px;
  border-radius: 12px;
  gap: 16px;
  flex-wrap: wrap;


  &-firstCircle {
    border-radius: 100%;
    border: 2px solid rgba(220, 104, 3, 0.2);
    height: max-content;
    width: max-content;
    box-sizing: border-box;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-secondCircle {
    border-radius: 100%;
    border: 2px solid rgba(220, 104, 3, 0.4);
    height: max-content;
    width: max-content;
    box-sizing: border-box;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & h3 {
    color: $color-warning-700;
    @extend .text-sm;
    @extend .font-semibold;
  }

  & p {
    color: $color-warning-700;
    @extend .text-sm;
    @extend .font-regular;
  }
}