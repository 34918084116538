.restaurantDetails {
  background: $color-gray-50;
  height: 99%;
  overflow: scroll;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 24px;
  gap: 16px;
  border-radius: 12px;
  background-color: $color-white;

  @media (min-width: 768px) {
    width: 325px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &-image {
    width: 78px;
    height: 78px;
    border-radius: 50%;
  }

  &-info {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    height: auto;
    gap: 16px;

    &-noFood {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 16px;
      justify-content: center;
      border-top: 2px solid $color-gray-200;
      border-bottom: 2px solid $color-gray-200;

      &-image {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        padding: 20px;
        border-radius: 50%;
        background-color: $color-gray-50;
        box-sizing: border-box;
      }

      &-label {
        color: $color-gray-950;

        @extend .text-lg;
        @extend .font-semibold;
      }
    }

    &-restaurant {
      display: flex;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      border-radius: 8px;

      &-info {
        display: flex;
        flex-direction: column;
        gap: 2px;

        &-text {
          color: $color-gray-600;
          text-align: left;

          @extend .text-md;
          @extend .font-regular;
        }

        &-name {
          color: $color-gray-950;
          text-align: left;
          @extend .text-lg;
          @extend .font-semibold;  
        }
      }

      &-notesButton {
        display: flex;
        align-items: center;
        padding: 4px 16px;
        gap: 4px;
        border-radius: 80px;
        background-color: $color-gray-100;
        cursor: pointer;

        & img {
          width: 16px;
          height: 16px;
          min-height: 16px;
          min-width: 16px;
        }
      }
    }
  }

  &-paymentInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: min-content;

    &::-webkit-scrollbar {
      display: none;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-label {
        color: $color-gray-700;
        @extend .text-sm;
        @extend .font-medium;  
      }

      &-sum {
        color: $color-gray-600;
        @extend .text-sm;
        @extend .font-regular; 
      }
    }
  }

  &-label {
    color: $color-gray-900;
    @extend .xs;
    @extend .font-semibold;
  }
}