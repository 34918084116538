.fileUploadBase {
  position: relative;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid $color-gray-200;
  background: $color-white;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &-fileTypeIcon {
    width: 40px;
    height: 40px;

    &-sm {
      width: 20px;
      height: 20px;
    }
  }


  &-textContainer {
    max-width: 65%;
    overflow: scroll;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-actionIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-name {
    color: $color-gray-700;

    @extend .text-sm;
    @extend .font-medium;

    &-withAction {
      color: $color-primary-600;
      text-decoration: none;
      cursor: pointer;

      @extend .text-sm;
      @extend .font-medium;
    }
  }

  &-fileSize {
    color: $color-gray-600;

    @extend .text-sm;
    @extend .font-regular;
  }

  &-downloaded {
    border-radius: 8px;
    border: 1px solid $color-primary-600;
    background: $color-primary-600;
    display: flex;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    padding: 3px;
    justify-content: center;
    align-items: center;
  }
}