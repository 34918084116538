.newCategoryDropdown {
  &-colorPicker {
    position: absolute;
    top: 45px;
    right: 0;
    width: 70%;
    display: inline-flex;
    padding: 16px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: $color-white;        
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  }

  &-colorCircle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    min-width: 10px;

    &-red {
      background-color: $color-error-500;
    }
    &-orange {
      background-color: $color-warning-500;
    }
    &-yellow {
      background-color: $color-yellow-400;
    }
    &-success {
      background-color: $color-success-500;
    }
    &-blue {
      background-color: $color-primary-600;
    }
    &-purple {
      background-color: $color-purple-500;
    }
    &-blueLight {
      background-color: $color-blue-500;
    }
  }

  &-teamTicket {
    &-red {
      background-color: $color-error-50;
    }
    &-orange {
      background-color: $color-warning-50;
    }
    &-yellow {
      background-color: $color-yellow-50;
    }
    &-success {
      background-color: $color-success-50;
    }
    &-blue {
      background-color: $color-primary-50;
    }
    &-purple {
      background-color: $color-purple-50;
    }
    &-blueLight {
      background-color: $color-blue-50;
    }
  }
}