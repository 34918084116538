.homePage {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &-header {
    width: 100%;
    gap: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-right: 16px;
    padding-left: 16px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    &-createEvent {
      display: flex;
      align-items: center;
      background-color: $color-primary-600;
      width: 100%;
      min-width: 320px;
      padding: 12px 16px 12px 16px;
      border-radius: 12px;
      gap: 16px;
      box-sizing: border-box;
      cursor: pointer;
      height: 86px;
      box-sizing: border-box;
      transition: background-color 0.3s ;

      &:hover {
        background-color: $color-primary-700;
      }

      @media (min-width: 768px) {
        width: 320px;
      }

      &-img {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 20px;
        cursor: pointer;
      }

      &-text {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &-title {
          color: $color-white !important;

          @extend .text-xl;
          @extend .font-bold;
        }

        &-description {
          color: $color-white !important;

          @extend .text-sm;
          @extend .font-regular;
        }
      }
    }

    &-dayInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 17px 24px;
      height: fit-content;
      box-sizing: border-box;
      border: 1px solid $color-gray-300;
      border-radius: 12px;
      background: linear-gradient(90deg, rgba(68, 76, 231, 0.5) 0%, rgba(68, 76, 231, 0.165) 100%), url('../../assets/images/dayInfoBackground.png');
      background-size: cover;
      background-position: 10%;
      justify-content: center;
      align-items: start;

      @media (min-width: 768px) {
        width: 70%;
        height: 86px;
        padding: 0 24px;
      }

      &-title {
        color: $color-gray-25 !important;

        @extend .text-xl;
        @extend .font-semibold;
      }

      &-description {
        color: $color-gray-200 !important;

        @extend .text-sm;
        @extend .font-regular;
      }
    }
  }

  &-content {
    padding: 0px 16px 16px 16px;
    gap: 24px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    height: 65%;
    overflow: visible;

    @media (min-width: 768px) {
      padding-bottom: 0;
      flex-direction: row;
      height: 100%;
    }

    &-todos {
      width: 100%;
      min-width: 100%;
      box-sizing: border-box;
      height: 90%;
      padding: 24px;
      overflow: scroll;
      border-radius: 12px;
      border: 1px solid $color-gray-200;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      display: flex;
      flex-direction: column;
      gap: 20px;
      min-height: 90%;

      @media (min-width: 768px) {
        width: 320px;
        min-width: 320px;
      }

      &-min-w-200 {
        @media (min-width: 768px) {
          width: 100%;
          min-width: 200px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;


        &-topic {
          display: flex;
          align-items: center;
          gap: 8px;

          &-title {
            color: $color-gray-900;

            @extend .text-lg;
            @extend .font-semibold;
          }

          &-todosCount {
            width: 24px;
            height: 24px;
            padding: 2px 8px 2px 8px;
            border-radius: 50%;
            box-sizing: border-box;
            border: 1px solid $color-gray-200;
            background-color: $color-gray-50;
            display: flex;
            align-items: center;
            justify-content: center;

            color: $color-gray-700;

            @extend .text-xs;
            @extend .font-medium;
          }
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &-listIcon {
        width: 80px;
        height: 80px;
        padding: 20px;
        gap: 10px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: $color-gray-50;
      }
    }
  }

  &-main {
    border-radius: 0;
    height: max-content;

    @media (min-width: 768px) {
      height: 100%;
    }
  }
}