.notFound {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 64px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  position: relative;

  &-background {
    position: absolute;
    width: 80%;
    height: 100%;
    box-sizing: border-box;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
    top: -14%;
    mask-image: url('../../assets/images/Background-mask.png');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
  }

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    &-info {
      display: flex;
      width: 90%;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      &-logo {
        width: 202px;
        height: 48px;
      }

      & h1 {
        white-space: nowrap;
        color: $color-gray-900;
      }
    }
  }

  &-actions {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}