.calendarSchedule {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  position: relative;
  z-index: 30;

  &-month {
    color: $color-gray-900;
    
    @extend .text-lg;
    @extend .font-semibold;
  }

  &-table {
    display: flex;
    flex-direction: column;

    &-grid {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(7, minmax(0, 1fr));

      &-days {
        grid-auto-rows: 200px;
      }
    }

    &-header {
      align-items: center;
      display: flex;
      border-bottom: 1px solid  #DADCE0;
      background: $color-primary-25;
      height: 65px;
      border: 2px solid rgba(218, 220, 224, 0.60);
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      position: sticky;
      top: 0;
      z-index: 100;

      &-month {
        height: 36px;
      }
    }

    &-body {
      &-day {
        color: #333;

        @extend .text-sm;
        @extend .font-regular;
      }
    }
  }
}

.monthCalendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 24px 20px 24px;
  gap: 12px;
  background-color: $color-white;
  box-sizing: border-box;
  border: 1px solid $color-gray-100;

  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &-calendar {
    &-tabs {
      display: flex;
      align-items: center;
      gap: 8px;

      &-tab {
        width: 40px;
        height: 40px;
        padding: 8px;
        gap: 8px;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s linear;
      
        &-selected {
          background-color: $color-primary-600;
        }
      }
    }

    &-header {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  &-changeYearHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    box-sizing: border-box;
    z-index: 30;
    height: 36px;

    &-img {
      cursor: pointer;
    }

    &-scrolled {
      padding: 6px;
      background: rgba(249, 250, 251, 1);
      // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);

box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    }
  }
}