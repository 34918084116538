.eventDetails {

  &_content {
    padding: 0 18%;
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &-createEvent {
      padding: 0 12px;

      @media (min-width: 768px) {
        padding: 0 18%;
      }
    }

    &-absolute {
      padding-top: 112px;
    }

    &-location {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      gap: 8px;
      padding: 10px 14px;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--gray-300, #D0D5DD);
      background: var(--base-white, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
      }

      &-title {
        color: $color-gray-700;
        @extend .text-sm;
        @extend .font-medium;
      }

      &-main {
        color: $color-gray-900;
        @extend .text-md;
        @extend .font-medium;
      }

      &-support {
        color: $color-gray-600;
        @extend .text-md;
        @extend .font-regular;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-agenda {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 12px;

      @media (min-width: 768px) {
        padding: 0 18%;
      }

      &-actions {
        box-sizing: border-box;
        margin-top: auto;
        flex-direction: row;
        padding-top: 24px;
        margin-top: auto;
      }
    }
  }

  &_container {
    display: flex;
    position: relative;
    background-color: #f2f4f7;
    height: 100%;
    width: 100%;
    overflow: hidden;

    @media (min-width: 768px) {
      background-color: $color-gray-50;
      min-height: 100%;
    }

    &-agenda {
      @media (min-width: 768px) {
        background-color: $color-gray-50;
        min-height: 100%;
      }
    }
  }

  &_main-container {
    // margin: 20px 0 0;
    width: 75%;
    margin: 40px auto;
    border: 1px solid $color-gray-200;
    border-radius: 12px;
    overflow: hidden;
    max-width: 900px;

    &-noMaxWidth {
      width: 100%;
      max-width: none;
      margin: 0;
    }

    &-withoutBorder {
      border: none;
    }

    &-noMarginTop {
      margin-top: 0;
    }

    &-noMaxWidth {
      max-width: none;
    }
    // @media (min-width: 768px) {
    //   margin: 12px 0;
    //   max-width: 75%;
    // }

    &-reviewPage {
      margin: 0;
      height: calc(100% - 75px);

      @media (min-width: 768px) {
        margin: 12px auto;
        max-width: 75%;
        height: auto;
      }
    }

    &-reviewEventDetails {
      margin-bottom: 105px;
      overflow-y: visible;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-reviewAndSubmit {
      padding: 0 5%;
    }
  }

  &_circles {
    position: absolute;
    left: -104px;
    top: -85px;
    display: flex;
  }

  &-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid;
    box-sizing: border-box;

    &-first {
      width: 96px;
      height: 96px;
      border-color: rgba(234,236,240);
    }
    &-second {
      width: 144px;
      height: 144px;
      border-color: rgba(234,236,240, 0.7);
    }
    &-third {
      width: 192px;
      height: 192px;
      border-color: rgba(234,236,240, 0.6);
    }
    &-fourth {
      width: 240px;
      height: 240px;
      border-color: rgba(234,236,240, 0.5);
    }
    &-fifth {
      width: 288px;
      height: 288px;
      border-color: rgba(234,236,240, 0.4);
    }
  }
}

.eventDetails_main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  // border-radius: 32px 32px 0px 0px;
  background: $color-white;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  padding: 40px 0 20px 0;

  &-withoutPadding {
    padding: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &-createEvent {
    width: auto;
    max-width: 900px;
    margin: 0 12px;
    padding: 16px 12px 20px;
    border: 1px solid $color-gray-200;
    border-radius: 12px;

    @media (min-width: 768px) {
      width: 75%;
      margin: 0 auto;
      padding: 40px 0 20px 0;
      border: none;
    }
  }

  &-bgTransparent {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    // border-radius: 40px 0px 0px 40px;
    overflow: hidden;
  }

  &-reviewPage {
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0px;
    
    @media (min-width: 768px) {
      border-radius: 40px 0px 20px 0px;
      overflow: hidden;
    }
  }

  &-header {
    top: 0;
    display: flex;
    position: relative;
    padding: 32px 16px;
    align-items: center;
    gap: 16px;
    z-index: 20;
    @extend .text-md;
    box-sizing: border-box;
    border-bottom: 1px solid $color-gray-200;
    background-color: $color-white;
    justify-content: space-between;

    &-homePage {
      border-bottom: none;
      background-color: transparent;
    }

    &-absolute {
      position: absolute;
      z-index: 50;
      width: 100%;
    }

    &-review {
      padding-bottom: 0;
    }

    &-error {
      padding: 122px 16px 32px;
    }

    &-scrolled {
      border-bottom: 1px solid $color-gray-200;
      margin-bottom: 0;
      z-index: 30;
      overflow: hidden;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    }

    & h3 {
      color: $color-primary-600;
      @extend .font-semibold;
    }

    & p {
      color: $color-gray-600;
      @extend .font-regular;
    }
  }

  &-error {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: $color-error-100;
    z-index: 20;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 32px;

    &-firstCircle {
      border-radius: 100%;
      border: 2px solid rgba(217, 45, 32, 0.2);
      height: max-content;
      width: max-content;
      box-sizing: border-box;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-secondCircle {
      border-radius: 100%;
      border: 2px solid rgba(217, 45, 32, 0.4);
      height: max-content;
      width: max-content;
      box-sizing: border-box;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & h3 {
      color: $color-error-700;
      @extend .text-sm;
      @extend .font-semibold;
    }

    & p {
      color: $color-error-700;
      @extend .text-sm;
      @extend .font-regular;
    }
  }

  &-close {
    position: absolute;
    top: 15px;
    right: 24px;
    cursor: pointer;

    &-error {
      top: 124px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 20;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    &-inviteAttendees {
      @media (min-width: 768px) {
        width: 92%;
        margin: 0 auto;
      }
    }

    &-agenda {
      overflow-y: scroll;
      
      @media (min-width: 768px) {
        width: 92%;
        margin: 0 auto;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
    
      &::-webkit-scrollbar-track {
        background: $color-white; 
        border: none;
      }
    
      &::-webkit-scrollbar-thumb {
        background: $color-gray-200;
        border-radius: 8px;
        height: 50%;
      }

      &-draggable {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: max-content;
      }
    }

    &-pickers {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (min-width: 768px)  {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
      }
    }
  }

  &-footer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background-color: $color-white;
    gap: 12px;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid $color-gray-200;
    padding: 24px;
    z-index: 100;

    &-positionAbsolute {
      position: absolute;
      top: 0;
      left: 112px;
      width: calc(100% - 112px);
      border-bottom: 1px solid $color-gray-200;
    }

    &-eventsPage {
      position: absolute;
      top: 76px;
      width: 100%;
      border-bottom: 1px solid $color-gray-200;

      @media (min-width: 768px) {
        width: calc(100% - 112px);
        left: 112px;
        top: 0;
      }
    }

    &-buttonSaveAsDraft {
      display: flex;
      gap: 8px;

      & p {
        white-space: nowrap;
      }
    }
  }
}

.eventAgenda {
  &-createNotification {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    border: 1px dashed $color-gray-600;
    border-spacing: 1px;
    background: $color-gray-50;
    width: 100%;
    box-sizing: border-box;
    padding: 24px 12px;
    gap: 10px;
    color: $color-gray-600;
    cursor: pointer;

    @extend .text-md;
    @extend .font-medium;

    &:hover {
      background: $color-gray-100;
    }
  }

  &-addAction {
    margin: 0 auto;
  }

  &-endTime {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;

    &-rectangles {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      width: 100%;

      &-rectangle {
        display: inline-block;
        height: 5px;
        width: 100%;

        border-radius: 40px;
        
        &-error {
          background: $color-error-600;
          box-shadow: 0px 0px 0px 4px #FEE4E2;
        }

        &-info {
          background: $color-gray-500;
          box-shadow: 0px 0px 0px 4px #F2F4F7;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &-end {
        white-space: nowrap;

        @extend .text-lg;
        @extend .font-bold;
        
        &-error {
          color: $color-error-600;
        }

        &-info {
          color: $color-gray-500;
        }
      }

      &-description {
        color: $color-gray-500;
        white-space: nowrap;

        @extend .text-sm;
        @extend .font-medium;
      }
    }
  }
}

.eventAddAttendees {
  &-addFirstAttend {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &-addAction {
    margin: 0 auto 0 0;
    padding: 0 24px;

    @media (min-width: 768px) {
      padding: 0;
    }
  }

  &-content {
    padding: 0;
  }

  &-footer {
    padding: 0 24px;
  }
}