.paymentMethodCard {
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  border: 1px;
  gap: 20px;
  border: 1px solid $color-gray-200;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &-info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-title {
      color: $color-gray-900;

      @extend .text-lg;
      @extend .font-semibold;
    }

    &-description {
      color: $color-gray-600;

      @extend .text-sm;
      @extend .font-regular;
    }
  }

  &-card {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px;
    gap: 16px;
    border: 1px solid $color-gray-200;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-info {
      display: flex;
      align-items: start;
      gap: 16px;

      &-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &-title {
        color: $color-gray-700;

        @extend .text-sm;
        @extend .font-medium;
      }

      &-description {
        color: $color-gray-600;

        @extend .text-sm;
        @extend .font-regular;
      }
    }
  }
}