.agendaItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;

  &-subBlockPreview {
    padding: 12px 16px;
  }
  
  &-withoutEditActions {
    border: 1px solid $color-gray-200;
    border-radius: 12px;
  }

  &-maxHeight {
    height: max-content;
  }

  &-timeInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    width: 100%;
    background-color: $color-primary-600;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    overflow: hidden;
    color: $color-white;
    box-sizing: border-box;

    &-withoutEditActions {
      background-color: inherit;
      color: $color-primary-600;
      padding: 16px 16px 0 16px;
    }

    &-container {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-time {
      white-space: nowrap;
      @extend .text-sm;
      @extend .font-medium;

      &-circle {
        width: 8px;
        min-width: 8px;
        height: 8px;
        min-height: 8px;
        border-radius: 50%;
        background-color: $color-white;

        &-withoutEditActions {
          background: $color-primary-600;
        }    
      }
    }

    &-rectangle {
      width: 86px;
      height: 2px;
      border-radius: 40px;
      background: $color-primary-600;
      box-shadow: 0px 0px 0px 4px #EAEBFF;
      margin-top: 4px;

      &-break {
        background: $color-gray-600;
        box-shadow: 0px 0px 0px 4px #F2F4F7;
      }

      &-meal {
        background: $color-warning-500;
        box-shadow: 0px 0px 0px 4px #FFFAEB;
      }

      @media (min-width: 768px) {
        width: 2px;
        height: 100%;
      }
    }
  }

  &-info {
    display: flex;
    align-items: flex-start;
    border: 1px solid $color-gray-200;
    padding: 16px;
    gap: 8px;
    width: 100%;
    box-sizing: border-box; 
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &-withoutEditActions {
      border: none;
    }

    &-withWarning {
      border-radius: 0px 0px 10px 10px;
    }

    &:hover {
      background: $color-gray-100;
    }

    &-subBlockPreview {
      background: $color-white;

      &:hover {
        background: $color-white;
      }
    }

    &-dragBtn {
      &:hover {
        background-color: $color-primary-700;
        border-radius: 8px;
      }
    }

    &-actions {
      display: flex;
      gap: 4px;
      align-items: center;
    }

    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      &-wrapper {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin-right: auto;
      }

      &-text {
        color: $color-gray-900;
        white-space: nowrap;
        max-width: 100%;
        overflow:hidden; 
        text-overflow: ellipsis;
        @extend .text-lg;
        @extend .font-medium;
      }

      &-duration {
        padding: 2px 8px;
        color: $color-gray-700;
        text-align: center;
        border-radius: 6px;
        border: 1px solid $color-gray-300;
        background: $color-white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        white-space: nowrap;
      }

      &-icon {
        position: relative;

        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          top: -9%;
          left: 8%;
          z-index: -1;
          width: 100%;
          height: 100%;
          transform: rotate(15deg);
          border-radius: 6px;
          background: $color-primary-200;
          z-index: 1;
        }

        &-break {
          &::before {
            background: $color-gray-200;
          }
        }

        &-meal {
          &::before {
            background: $color-warning-200;
          }
        }
      }
    }

    &-description {
      color: $color-gray-600;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      @extend .text-sm;
      @extend .font-regular;
    }
  }

  &-warning {
    width: 100%;
    height: 60px;
    padding: 16px 20px 16px 16px;
    border-radius: 12px 12px 0px 0px;
    gap: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 2px solid $color-warning-600;
    background-color: $color-warning-50;

    &-image {
      border: 4px solid rgba(255, 250, 235, 0.5);
      background: $color-warning-100;
      width: 32px;
      height: 32px;
      padding: 8px;
      border-radius: 28px;
      box-sizing: border-box;
    }

    &-text {
      color: $color-warning-800;

      @extend .text-md;
      @extend .font-semibold;
    }
  }

  &-meal {
    background-color: $color-pink-600;
  }

  &-session {
    background-color: $color-primary-600;
  }

  &-break {
    background-color: $color-gray-600;
  }
}