.errorMessage {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid $color-error-300;
  background: $color-error-25;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &-title {
    color: $color-error-700;
    @extend .text-sm;
    @extend .font-semibold;
  }

  &-description {
    color: $color-error-700;
    @extend .text-sm;
    @extend .font-regular;
  }

  &-link {
    color: $color-error-700;
    @extend .text-sm;
    @extend .font-regular;
    text-decoration: underline;
  }
}