.vendorMovementsTable {
  grid-template-columns: 23% 22% 8% 12% 24% 11%;

  &-content {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    border-top: 1px solid $color-gray-200;

    &-progress {
      border-radius: 16px;
      display: flex;
      padding: 2px 8px 2px 6px;
      align-items: center;
      gap: 4px;
      @extend .text-xs;
      @extend .font-medium;

      &-success {
        border: 1px solid $color-success-200;
        background: $color-success-50;
        color: $color-success-700;
      }

      &-error {
        border: 1px solid $color-error-200;
        background: $color-error-50;
        color: $color-error-700;
      }
    }
  }
}