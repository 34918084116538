.modalNotification {
  background-color: rgba(16, 24, 40, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  backdrop-filter: blur(8px);

  &-container {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    border-radius: 12px;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    width: 90%;
    max-width: 400px;
    height: max-content;
    overflow: hidden;
    padding: 24px;
    box-sizing: border-box;

    &-success {
      border-radius: 12px;
      border: 1px solid $color-gray-200;
      padding: 16px;
      z-index: 100;
      top: 70px;
      right: -190px;
      left: auto;
      bottom: auto;
      position: fixed;
    }

    &-agenda {
      max-width: none;
      overflow: inherit;
      left: 0;
      top: auto;
      bottom: 0;
      right: 0;
      transform: none;
      width: 100%;
      height: 98%;
      border-radius: 0;
      border-top-right-radius: 32px;
      border-top-left-radius: 32px;

      @media (min-width: 768px) {
        left: auto;
        top: 0;
        bottom: 0;
        right: 0;
        transform: none;
        width: 45%;
        height: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &-inviteAttendees {
      max-width: 640px;
      overflow: inherit;
    }

    &-selectMealsCustomization {
      max-width: 640px;
      overflow: scroll;
      max-height: 80%;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-invite {
      padding: 0;
    }

    &-attachDocument {
      width: 90%;
      padding: 24px;
      max-height: 70%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (min-width: 768px) {
        width: 50%;
      }
    }
  }

  &-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 20px;
    width: 100%;
    z-index: 30;

    &-success {
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 0;
    }

    &-error {
      margin-bottom: 32px;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      &-success {
        width: fit-content;
      }

      &-error {
        width: fit-content;
      }

      &-icon {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 8px solid $color-error-50;
        background: $color-error-100;
        box-sizing: border-box;

        &-warning {
          border: 8px solid $color-warning-50;
          background: $color-warning-100;
        }

        &-successSecond {
          border: 8px solid $color-success-50;
          background: $color-success-100;
        }

        &-success {
          border: 2px solid rgba(7,148,85, 0.3);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: none;
        }

        &-error {
          border: 2px solid rgba(255, 0, 0, 0.357);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: none;
        }

        &-errorSecond {
          border: 8px solid $color-error-50;
          background: $color-error-100;
        }

        &-container {
          &-success {
            display: flex;
            border: 2px solid rgba(7,148,85, 0.1);
            border-radius: 50%;
            width: 38px;
            height: 38px;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
          }

          &-error {
            display: flex;
            border: 2px solid rgba(255, 0, 0, 0.2);
            border-radius: 50%;
            width: 38px;
            height: 38px;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
          }
        }
      }

      &-close {
        cursor: pointer;

        &-success {
          position: absolute;
          top: 10%;
          left: 90%;
          cursor: pointer;
          z-index: 30;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      width: 100%;

      & h3 {
        color: $color-gray-900;
        @extend .text-lg;
        @extend .font-semibold;

        &-error {
          @extend .text-sm;
        }
      }

      & p {
        color: $color-gray-600;
        @extend .text-sm;
        @extend .font-regular;
      }

      &-success {
        margin-top: 3%;

        & h3 {
          @extend .text-sm;
          @extend .font-semibold;
        }
      }
    }
  }

  &-main {
    margin-bottom: 32px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    overflow-y: scroll;
    position: relative;
    padding: 4px;
    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 768px) {
      overflow-y: hidden;
    }

    &-agenda {
      padding: 0;
      overflow-y: inherit;
    }
  
    &-duration {
      position: relative;
      height: 240px;
    }
  }

  &-actions {
    display: flex;
    gap: 12px;
    width: 100%;
    flex-wrap: wrap;

    &-flex-end {
      @media (min-width: 768px) {
        justify-content: flex-end;
      }
    }

    &-attachModal {
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
    }

    &-agenda {
      position: fixed;
      bottom: 24px;
      right: 24px;
    }
  }

  &-circles {
    position: absolute;
    top: -8px;
    left: 12px;

    &-container {
      position: absolute;
      width: 210px;
      height: 210px;
      top: 0px;
      left: 0px;
      overflow: hidden;
    }

    &-error {
      position: absolute;
      top: -11px;
      left: 9px;
    }
  }
}

.modalInvite {
  &-noResult {
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;

        & p {
          text-align: center;
        }

        &-title {
          color: $color-gray-900;
          @extend .text-lg;
          @extend .font-semibold;
        }

        &-description {
          color: $color-gray-600;
          @extend .text-sm;
          @extend .font-regular;
        }
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      &-new {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      margin: 0 auto;
      max-width: 350px;
    }
  }

  &-actions {
    position: fixed;
    bottom: -1px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 8px 8px 18px;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    z-index: 1000;
    

    @media (min-width: 768px) {
      border-bottom-right-radius: 11px;
      border-bottom-left-radius: 11px;
    }

    border-top: 1px solid $color-gray-200;
    background: $color-primary-950;

    &-selectedItems {
      color: $color-gray-25;
      @extend .text-sm;
      @extend .font-semibold;
    }
  }

  &-closeIcon {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &-stepIcon {
    position: absolute;
    top: 22px;
    left: 24px;
  }

  &-content {
    margin-top: 15%;
    margin-bottom: 45px;
  }

  &-title {
    position: absolute;
    top: 35px;
    left: 95px;
  }
}

.modalSaveAsDraft {
  position: fixed;
}

.modalReviewAgenda {
  max-height: 90% !important;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modalEventCreated {
  max-width: 470px;
}

.modalNotificationNew {
  background-color: $color-white;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  width: 45%;
  padding: 24px;
  box-sizing: border-box;
  gap: 32px;
  border-radius: 12px;
}