.loader {
  // border: 8px solid $color-white;
  // border-top: 8px solid $color-primary-700;
  width: 150px;
  height: 150px;
  // animation: spin 2s linear infinite;

  &-sm {
    width: 32px;
    height: 32px;
    // border: 5px solid $color-white;
    // border-top: 5px solid $color-primary-700;
  }

  &-md {
    width: 48px;
    height: 48px;
    // border: 6px solid $color-white;
    // border-top: 6px solid $color-primary-700;
  }

  &-lg {
    width: 56px;
    height: 56px;
    // border: 7px solid $color-white;
    // border-top: 7px solid $color-primary-700;
  }
}


// @keyframes spin {
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// }