.reviewEventDetailsModal {
  border-radius: 12px;
  border: 1px solid $color-gray-200;
  background: $color-white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  &-name {
    color: $color-gray-900;

    @extend .text-md;
    @extend .font-medium;
  }

  &-description {
    color: $color-gray-600;

    @extend .text-md;
    @extend .font-regular;
  }

  &-topic {
    color: $color-gray-500;

    @extend .text-sm;
    @extend .font-medium;
  }
}