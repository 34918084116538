.customTabs {
  display: flex;
  border-radius: 8px;
  border: 1px solid $color-gray-300;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: max-content;
  position: relative;
  z-index: 30;

  &-newDesign {
    border-radius: 32px;
  }

  &-tab {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: $color-white;
    color: $color-gray-700;
    @extend .text-sm;
    @extend .font-semibold;
    cursor: pointer;
    white-space: nowrap;

    &-withBorderRight {
      border-right: 1px solid $color-gray-300;
    }

    &-notLast {
      border-right: 1px solid $color-gray-300;
    }

    &-first {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      &-newDesign {
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
      }
    }

    &-last {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      &-newDesign {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
      }
    }

    &-selected {
      background: $color-primary-50;
      color: $color-gray-800;
    }
  }
}