.subBlockItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  box-sizing: border-box;

  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-main {
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    border: 1px solid $color-gray-200;
    border-radius: 12px;
    box-sizing: border-box;

    &-info {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &-title {
        color: $color-gray-950;

        @extend .text-lg, .font-semibold;
      }

      &-time {
        color: $color-gray-950;

        @extend .text-sm, .font-regular;
      }
    }

    &-docs {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }
}