.radioButtons {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &-label {
    color: $color-gray-700;

    @extend .text-sm;
    @extend .font-medium;
  }

  &-button {
    display: flex;
    align-items: center;
    gap: 8px;

    &-circle {
      width: 6px;
      height: 6px;
      background-color: $color-primary-600;
      border-radius: 50%;

      &-container {
        width: 16px;
        height: 16px;
        padding: 2px;
        background: $color-white;
        border: 1px solid $color-gray-300;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-sizing: border-box;

        &-selected {
          background: $color-primary-50;
          border: 1px solid $color-primary-600;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;

      &-name {
        color: $color-gray-700;
        user-select: none;
  
        @extend .text-sm;
        @extend .font-medium;
      }

      &-price {
        color: $color-gray-600;
  
        @extend .text-sm;
        @extend .font-regular;
      }
    }
  }
}