.addAgendaBlock {
  &-header {
    padding-bottom: 0;
  }

  &-main {
    height: calc(100% - 89px);

    &-container {
      height: calc(100% - 89px);
    }

    &-add {
      background: $color-white;
      min-width: 340px;
      padding: 24px;
      box-sizing: border-box;
    }

    &-preview {
      width: 100%;
      background-color: $color-gray-100;
      padding: 5% 40px 0;
      box-sizing: border-box;
    }
  }
}