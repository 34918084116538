.contactsTable {
  width: 100%;
  overflow: scroll;
  min-height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    max-height: 100%;
    min-height: unset;
  }

  &-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-tr {
      &:hover {
        background-color: $color-gray-100;
      }
    }
  }

  &-selected {
    border-bottom: 1px solid var(--Gray-200, #EAECF0);
    background: #EEF4FF;
  }

  &-description {
    color: $color-gray-600;

    @extend .text-sm;
    @extend .font-regular;
  }

  &-header {
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
}

.contacts {
  padding: 40px;
  overflow: hidden;
  border-radius: 12px;
  background-color: $color-white;

  @media (min-width: 768px) {
    border: none;
  }
}