.gallery {
  position: relative;
  width: 100%;
  height: 292px;
  border-radius: 12px;

  &-photo {
    width: 100%;
    height: 292px;
    min-width: 100%;
    border-radius: 12px;
  }

  &-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    background-color: $color-white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-200;
    }

    &-left {
      left: 30px;
    }

    &-right {
      right: 30px;
    }
  }

  &-text {
    position: absolute;
    bottom: 5px;
    right: 45px;
    background-color: $color-white;
    border-radius: 16px;
    padding: 2px 4px;

    color: $color-gray-700;

    @extend .text-sm;
    @extend .font-semibold;
  }
}