.countryDropdown {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  position: relative;

  &-abbr {
    color: $color-gray-900;

    @extend .text-md;
    @extend .font-medium;
  }

  &-label {
    color: $color-gray-700;

    @extend .text-sm;
    @extend .font-medium;
  }

  &-input {
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;

    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--base-white, #FFF);

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &-container {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      & input {
        width: 100%;
        border: 0;
        outline: 0;

        color: $color-gray-900;

        @extend .text-md;
        @extend .font-medium;
      }

      &-input {
        color: $color-gray-600 !important;

        @extend .text-md;
        @extend .font-regular;
      }
    }
  }
}