.userInviteTable {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-gray-200;
  border-radius: 12px;
  box-sizing: border-box;
  width: 100%;

  &-top {
    display: flex;
    padding: 3px 24px;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-bottom: 1px solid $color-gray-200;
    background: $color-primary-950;
    color: $color-gray-25;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    @extend .text-xs;
    @extend .font-medium;
  }

  &-header {
    border-bottom: 1px solid $color-gray-200;
    background: $color-gray-50;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    &-container {
      display: grid;
      grid-template-columns: 60% 25% 10%;
      width: 100%;

      @media (min-width: 768px) {
        grid-template-columns: 60% 20% 10%;
      }
    }

    &-billings {
      display: grid;
      width: 100%;
      grid-template-columns: 40% 12% 12% 12% 10% 14%;;
    }

    &-invitees {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 12px 24px;
      justify-content: flex-start;
      color: $color-gray-600;
      margin-right: auto;
      box-sizing: border-box;
      @extend .text-xs;
      @extend .font-medium;
    }

    &-status {
      display: flex;
      align-items: center;
      padding: 12px 24px;
      justify-content: flex-start;
      box-sizing: border-box;
      color: $color-gray-600;
      @extend .text-xs;
      @extend .font-medium;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;

    &-container {
      display: grid;
      grid-template-columns: 55% 25% 10%;
      border-bottom: 1px solid $color-gray-200;
      background-color: $color-white;
      overflow-x: scroll;
      overflow-y: visible;
      padding-bottom: 90px;
      margin-bottom: -90px;
      
      &::-webkit-scrollbar {
        display: none;
      }

      &-invitedAttendees {
        display: grid;
        grid-template-columns: 44% 20% 20% 16%;
        border-bottom: 1px solid $color-gray-200;
        background-color: $color-white;
        overflow-y: visible;
      }
      
      &:last-child {
        padding-bottom: 0px;
        margin-bottom: 0px;
      }

      @media (min-width: 768px) {
        grid-template-columns: 60% 20% 10%;
      }

      &-billings {
        grid-template-columns: 40% 12% 12% 12% 10% 14%;;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &-isOdd {
        background-color: $color-gray-50;
      }
    }

    &-item {
      display: flex;

      &-info {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 12px 24px;
        justify-content: flex-start;
        margin-right: auto;

        &-name {
          color: $color-gray-900;
          @extend .text-sm;
          @extend .font-medium;
        }

        &-email {
          color: $color-gray-600;
          @extend .text-xs;
          @extend .font-regular;
        }
      }

      &-status {
        padding: 12px 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 10px;

        &-info {
          color: $color-gray-700;
          padding: 2px 8px;
          height: max-content;
          @extend .text-xs;
          @extend .font-medium;
          border-radius: 16px;
          border: 1px solid $color-gray-200;
          background: $color-gray-50;
          white-space: nowrap;

          &-success {
            color: $color-success-700;
            border: 1px solid $color-success-200;
            background: $color-success-50;
          }

          &-error {
            color: $color-error-700;
            border: 1px solid $color-error-200;
            background: $color-error-50;
          }

          &-warning {
            color: $color-warning-700;
            border: 1px solid $color-warning-200;
            background: $color-warning-50;
          }
        }
      }

      &-actions {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 4px;
        margin-left: 20px;

        @media (min-width: 768px) {
          margin: 0;
        }
      }
    }
  }
}