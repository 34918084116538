.todoCard {
  border-radius: 12px;
  border: 1px solid $color-gray-200;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  width: 100%;

  &-content {
    display: flex;
    padding: 24px;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid $color-gray-200;
    flex-direction: column;

    &-description {
      color: $color-gray-600;

      @extend .text-sm;
      @extend .font-regular;
    }
  }

  &-footer {
    padding: 24px 16px 24px 0;
    display: flex;
    justify-content: end;
  }
}