.logoRepeat {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: $color-primary-25;
  gap: 8px;
  padding: 4px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 2s height;
  transition: 1s opacity;
  border: 2px solid $color-primary-600;

  &-success {
    background: $color-success-25;
    border: 2px solid $color-success-600;
  }

  &-yellow {
    background: $color-yellow-25;
    border: 2px solid $color-yellow-600;
  }

  &-warning {
    background: $color-warning-25;
    border: 2px solid $color-warning-600;
  }

  &-error {
    background: $color-error-25;
    border: 2px solid $color-error-600;
  }

  &-purple {
    background: $color-purple-25;
    border: 2px solid $color-purple-600;
  }

  &-blueLight {
    background: $color-blueLight-25;
    border: 2px solid $color-blueLight-600;
  }

  &-pastEvent {
    background: $color-gray-50;
    border: 2px solid $color-gray-600;
  }

  &-sm {
    height: 80px;
  }

  &-md {
    height: 120px;
  }

  &-lg {
    height: 160px;

    @media (min-width: 768px) {
      height: 240px;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-second {
      transform: translateX(-15px);
      gap: 25px;
    }

    &-image {
      width: 40px;
      height: 40px;
      pointer-events: none;

      &-sm {
        width: 40px;
        height: 40px;
      }

      &-lg {
        width: 80px;
        height: 80px;
      }

      &-second {
        width: 16px;
        height: 16px;
        rotate: (-12deg);
        pointer-events: none;

        &-sm {
          width: 40px;
          height: 40px;
          rotate: (-12deg);
        }

        &-lg {
          width: 32px;
          height: 32px;
          rotate: (-12deg);
        }
      }
    }
  }
}