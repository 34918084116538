.zonePicker {
  display: contents;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;

  @media (min-width: 768px) {
    // overflow-x: hidden;
  }

  &-zone {
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 10px 18px;
    border-radius: 8px;
    background: $color-gray-50;
    text-overflow: ellipsis;
    @extend .text-md;
    @extend .font-medium;
  }
}