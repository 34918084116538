.progressSteps {
  width: 100%;

  &-container {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    box-sizing: border-box;
  }

  &-line {
    background: $color-gray-200;
    width: 25%;
    height: 2px;
    position: absolute;
    top: 22%;

    &-1 {
      left: 15%;
    }

    &-2 {
      left: 40%;
    }

    &-3 {
      left: 62%;
    }

    &-current {
      background: $color-gray-700;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    z-index: 20;
    cursor: not-allowed;

    &-passed {
      cursor: pointer;
    }
  
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25%;
      box-sizing: border-box;
      border: 1px solid $color-gray-200;
      background: $color-white;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      z-index: 2;
      position: relative;

      &-sm {
        width: 32px;
        height: 32px;
        padding: 8px;
      }

      &-md {
        width: 40px;
        height: 40px;
        padding: 10px;
      }

      &-lg {
        width: 56px;
        height: 56px;
        padding: 14px;
      }

      &-current {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-primary-200;
        background: $color-primary-25;
      }

      &-warning {
        border: 1px solid $color-warning-200;
        background: $color-warning-50;
      }

      &-success {
        border: 1px solid $color-success-200;
        background: $color-success-25;
      }

      &-primary {
        border: 1px solid $color-primary-200;
        background: $color-primary-600;
      }

      &-grayColor {
        border: 1px solid $color-gray-100;
        background: $color-gray-100;
      }

      &-secondaryColor {
        border: 1px solid $color-primary-100;
        background-color: $color-primary-100;
      }
    }
    
    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;

      & h3 {
        color: $color-gray-700;
        text-align: center;
        @extend .text-md;
        @extend .font-semibold;
      }

      & p {
        color: $color-gray-600;
        text-align: center;
        @extend .text-md;
        @extend .font-regular;
      }

      &-current {
        & h3 {
          color: $color-primary-600;
          text-align: center;
          @extend .text-md;
          @extend .font-semibold;
        }
      }
    }
  }
}