.counter {
  display: grid;
  grid-template-columns: 36px 49px 36px;
  gap: 8px;
  height: 36px;

  &-count {
    border: 1px solid #D0D5DD;
    width: 49px;
    height: 36px;
    padding: 4px 12px 4px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
}