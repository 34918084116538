.navigation {
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 60px;
  width: 100%;

  @media (min-width: 768px) {
    width: 25%;
    min-height: 100%;
    padding: 32px 16px;
  }

  &-search {
    padding: 0 8px;
    width: 100%;
  }

  &_header {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 768px) {
      justify-content: space-between;
      align-items: center;
    }

    &-logo {
      width: 143px;
      margin-right: auto;
      padding: 0 8px;
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &_userInfo {
    display: flex;
    flex-direction: row;
    gap: 12px;
    overflow: hidden;

    & img {
      width: 40px;
      height: 40px;
    }

    &-name {
      @extend  .text-sm;
      @extend .font-semibold;

      &-primary {
        color: $color-white;
      }

      &-default {
        color: $color-gray-700;
      }
    }

    &-email {
      @extend .font-regular;
      @extend  .text-sm;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;

      &-primary {
        color: $color-primary-200;
      }

      &-dark {
        color: $color-gray-100;
      }

      &-default {
        color: $color-gray-600;
      }
    }

    &-container {
      display: flex;
      width: 100%;
      padding: 24px 0 0px 8px;
      align-items: flex-start;
      justify-content: space-between;
      box-sizing: border-box;

      &-primary {
        border-top: 1px solid $color-primary-600;
      }

      &-default {
        border-top: 1px solid $color-gray-200;
      }

      &-dark {
        border-top: 1px solid $color-gray-600;
      }
    }
  }

  &_primary {
    background-color: $color-primary-700;
  }

  &_default {
    background-color: $color-white;
  }

  &_dark {
    background-color: $color-gray-950;
  }
}

.navigationNew {
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  width: max-content;
  background-color: $color-white;
  width: 100%;

  @media (min-width: 768px) {
    min-height: 100%;
    padding: 32px 16px;
    width: auto;
  }

  &-search {
    padding: 0 8px;
    width: 100%;
  }

  &_header {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 44px;

    @media (min-width: 768px) {
      flex-direction: column;
      width: auto;
      height: 112px;
    }

    &-logo {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: auto;
      padding: 0 8px;

      @media (min-width: 768px) {
        width: 143px;
      }
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  &-account {
    position: absolute;
    right: 7px;
    top: 85px;
    z-index: -100;
    padding: 20px 12px 5px 8px;
    box-sizing: border-box;
    border-radius: 8px;
    display: none;
    flex-direction: column;
    gap: 18px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    border: 1px solid $color-gray-200;
    background-color: $color-white;
    transition: 
      z-index 2s
    ;

    @media (min-width: 768px) {
      bottom: 10px;
      left: 125px;
      right: unset;
      top: unset;
    }

    &-isOpen {
      display: flex;
      z-index: 10001;
    }
  }

  &_userInfo {
    display: flex;
    flex-direction: row;
    gap: 12px;
    overflow: hidden;

    & img {
      width: 40px;
      height: 40px;
    }

    &-name {
      @extend  .text-sm;
      @extend .font-semibold;

      &-primary {
        color: $color-white;
      }

      &-default {
        color: $color-gray-700;
      }
    }

    &-email {
      @extend .font-regular;
      @extend  .text-sm;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;

      &-primary {
        color: $color-primary-200;
      }

      &-dark {
        color: $color-gray-100;
      }

      &-default {
        color: $color-gray-600;
      }
    }

    &-container {
      display: flex;
      width: 100%;
      padding: 24px 0 0px 8px;
      align-items: flex-start;
      justify-content: space-between;
      box-sizing: border-box;

      &-primary {
        border-top: 1px solid $color-primary-600;
      }

      &-default {
        border-top: 1px solid $color-gray-200;
      }

      &-dark {
        border-top: 1px solid $color-gray-600;
      }
    }
  }

  &_primary {
    background-color: $color-primary-700;
  }

  &_default {
    background-color: $color-white;
  }

  &_dark {
    background-color: $color-gray-950;
  }
}

.topSideNavigation {
  display: flex;
  width: 100%;
  padding: 12px 32px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  &-search {
    max-width: 320px;
  }

  &-default {
    border-bottom: 1px solid $color-gray-200;
    background: $color-white;
  }

  &-primary {
    border-bottom: 1px solid $color-primary-600;
    background: $color-primary-700;
  }

  &-dark {
    border-bottom: 1px solid $color-gray-600;
    background: $color-gray-950;
  }

  &-nav {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 768px) {
      justify-content: baseline;
      width: max-content;
    }

    &-items {
      display: flex;
      align-items: center;
      gap: 2px;

      &-item {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
        @extend .text-md;
        @extend .font-semibold;

        &-default {
          color: $color-gray-700;

          &-isSelected {
            border-radius: 6px;
            background: $color-gray-50;
          }

          &:hover {
            border-radius: 6px;
            background: $color-gray-50;
          }
        }

        &-primary {
          color: $color-primary-100;

          &-isSelected {
            border-radius: 6px;
            background: $color-primary-600;
          }

          &:hover {
            border-radius: 6px;
            background: $color-primary-600;
          }
        }

        &-dark {
          color: $color-gray-100;

          &-isSelected {
            background: $color-gray-800;
            border-radius: 6px;
          }

          &:hover {
            border-radius: 6px;
            background: $color-gray-800;
          }
        }
      }

      &-container {
        display: flex;
        align-items: center;

        &-mobile {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 40;
          display: flex;
          height: max-content;
          min-height: 100vh;
          overflow-x: hidden;
          z-index: 100;

          &-close {
            background: rgba(16,24,40, 0.8);
            width: max-content;
            padding: 8px;

            &-icon {
              display: flex;
              padding: 8px;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;

    &-container {
      display: flex;
      align-items: center;
      gap: 16px;

      &-userImg {
        width: 40px;
        height: 40px;
      }
    }
  }
}