.verifyCode {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;

  &-button {
    max-width: 340px;
    width: 100%;
  }

  &-container {
    width: 100%;
  }

  &-cell {
    display: flex;
    max-width: 80px;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    padding: 10px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 2px solid $color-primary-600;
    background: $color-white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: $color-primary-600;
    text-align: center;
    @extend .lg;
    @extend .font-medium;

    &:focus-visible {
      border: 2px solid $color-primary-600;
      background: $color-white;
      outline: none;
    }

    &-error {
      border: 2px solid $color-error-600;
      color: $color-error-600;
    }
  }
}