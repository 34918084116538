.upload {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid $color-gray-200;
  background: $color-white;

  &:hover {
    border-radius: 12px;
    border: 2px solid $color-primary-600;
  }

  &-disabled {
    border: 1px solid $color-gray-200;
    background: $color-gray-50;

    &:hover {
      border: 1px solid $color-gray-200;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-action {
      display: flex;
      gap: 4px;
      align-items: center;

      color: $color-gray-600;

      @extend .text-sm;
      @extend .font-regular;
    }

    &-supportText {
      color: $color-gray-600;

      @extend .text-xs;
      @extend .font-regular;

      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
}