.bottomNavigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  border-top: 1px solid $color-gray-200;
  background-color: $color-white;

  @media (min-width: 768px) {
    display: none;
  }

  &-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 12px 15px;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;

    @extend .text-xs;
    @extend .font-semibold;

    &-selected {
      background-color: $color-primary-600;
      color: $color-white;
    }
  }
}