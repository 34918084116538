.noEventsFound {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: relative;
  background: $color-white;
  overflow: hidden;

  &-icon {
    background-image: url(../../assets/images/folderWrap.svg);
    width: 32px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 30;

    &-img {
      padding-top: 0px;
    }

    &-wrap {
      width: 40px;
      height: 40px;
      display: flex;
      padding: 32px;
      align-items: center;
      justify-content: center;
      border-radius: 200px;
      background: linear-gradient(180deg, #F9FAFB 0%, #EDF0F3 100%);
    }
  }

  &-circles {
    position: relative;
    top: 40px;
    left: -17px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    max-width: 40%;
    position: relative;
    z-index: 30;

    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      text-align: center;

      &-title {
        color: $color-gray-900;

        @extend .text-md;
        @extend .font-semibold;
      }

      &-description {
        color: $color-gray-600;

        @extend .text-sm;
        @extend .font-regular;
      }
    }
  }
}