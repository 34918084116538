.restaurantItem {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 14px;
  border-radius: 8px;
  cursor: pointer;

  &-selected {
    border-radius: 8px;
    border: 1px solid $color-primary-600;
    background: $color-primary-25;

    &-icon {
      position: absolute;
      top: 14px;
      right: 14px;
    }
  }

  &-logo {
    min-width: 140px;
    width: 140px;
    height: 100px;
    border-radius: 12px;
  }

  &-logo-custom {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $color-gray-50;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  &-name {
    color: $color-gray-900;

    @extend .text-md;
    @extend .font-medium;
  }

  &-info {
    color: $color-gray-600;

    @extend .text-md;
    @extend .font-regular;
  }
  
  &-content {
    display: flex;
    padding: 2px 24px 2px 6px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    &-info {
      display: flex;
      align-items: flex-start;
      gap: 24px;
    }
  }
}