.customIcon {
  display: flex;
  width: max-content;
  align-items: flex-start;
  position: sticky;
  top: 0;
  gap: 16px;
  z-index: 20;
  @extend .text-md;

  & h3 {
    color: $color-primary-600;
    @extend .font-semibold;
  }

  & p {
    color: $color-gray-600;
    @extend .font-regular;
  }

  &-icon {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid var(--primary-200, #C7D7FE);
    background: var(--primary-25, #F5F8FF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 12px;
    margin-bottom: 34px;

    &::after {
      content: '';
      display: inline-block;
      top: 52px;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 2px;
      height: 26px;
      border-radius: 2px;
      background: $color-gray-200;
    }
  }

}