.tabs {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  width: 100%;

  &_tab {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    color: $color-gray-500;
    @extend .text-sm;
    @extend .font-semibold;
    border-radius: 6px;
    cursor: pointer;
    z-index: 20;

    &-current {
      color: $color-primary-700;
      background: $color-primary-50;
    }
  }
}