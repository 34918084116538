.teamMembersTable {
  grid-template-columns: 23% 10% 15% 17% 24% 11%;
  
  &-row {
    transition: background 0.3s;

    &:hover {
      background: $color-gray-100;
    }
  }

  &-container {
    display: flex;
    width: 90%;
    height: max-content;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid $color-gray-200;
    background: $color-white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    box-sizing: border-box;
    // margin: 10px auto;
  }

  &-header {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 20px 24px 0px 24px;
    margin-bottom: 20px;

    &-info {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &-table {
    border-radius: 0;
    width: max-content;
    min-width: 100%;

    &-container {
      width: 100%;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-cell {
      padding: 16px 24px;
      margin: 0;

      color: $color-gray-600;
      @extend .text-sm;
      @extend .font-regular;
      white-space: nowrap;

      &-flex {
        padding: 16px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-isActive {
      display: flex;
      padding: 2px 6px;
      align-items: center;
      gap: 4px;
      flex-wrap: nowrap;
      border-radius: 6px;
      border: 1px solid $color-gray-300;
      background: $color-white;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: $color-gray-700;

      @extend .text-xs;
      @extend .font-medium;
    }

    &-nickname {
      @extend .text-sm;
    }

    &-teams {
      display: flex;
      gap: 4px;

      &-team {
        display: flex;
        padding: 2px 8px;
        align-items: center;
        border-radius: 16px;
        @extend .text-xs;
        @extend .font-medium;

        &-primary {
          border: 1px solid $color-primary-200;
          background: $color-primary-50;
          color: $color-primary-700;
        }

        &-blue {
          border: 1px solid $color-blue-200;
          background: $color-blue-50;
          color: $color-blue-700;
        }

        &-indigo {
          border: 1px solid $color-indigo-200;
          background: $color-indigo-50;
          color: $color-indigo-700;
        }
      }
    }

    &-header {
      border-radius: 0;
      background-color: $color-white;
    }
  }
}