.border-none {
  border: none;
}

.border-box {
  box-sizing: border-box;
}

.halfWidth {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.height-70 {
  height: 70%;
}

.height-80 {
  height: 80%;
}

.gray-700 {
  color: $color-gray-700;
}

.selfStart {
  align-self: flex-start;
}

.color-gray-700 {
  color: $color-gray-700
}

.color-gray-600 {
  color: $color-gray-600 !important
}

.color-gray-900 {
  color: $color-gray-900
}

.color-gray-950 {
  color: $color-gray-950
}

.color-primary-600 {
  color: $color-primary-600
}

.color-error-700 {
  color: $color-error-700
}

.textCenter {
  text-align: center;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.color-primary-950 {
  color: $color-primary-950;
}

.items-start {
  align-items: flex-start;
}

.whiteSpaceNoWrap {
  white-space: nowrap;
}

.gap-12 {
  gap: 12px;
}

.gap-8 {
  gap: 8px;
}

.gap-4 {
  gap: 4px;
}

.gap-2 {
  gap: 2px;
}

.gap-6 {
  gap: 6px;
}

.gap-10 {
  gap: 10px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.z100 {
  z-index: 100;
}

.overflowYScroll {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.overflowXScroll {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.overflowYVisible {
  overflow-y: visible;
}

.gap-32 {
  gap: 32px;
}

.gap-52 {
  gap: 52px;
}

.spaceBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.justifyStart {
  justify-content: start;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.heightMaxContent {
  height: auto;
  max-height: 320px;
}

.border-radius-10 {
  border-radius: 10px;
}

.maxWidth-80 {
  max-width: 80%;
}

.pointerEventsNone {
  pointer-events: none;
}

.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.width-35 {
  width: 35%;
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-end {
  align-items: flex-end;
}

.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.border-radius-12 {
  border-radius: 12px;
}

.overflowXVisible {
  overflow-x: visible;
}

.overflowHidden {
  overflow: hidden;
}

.gap-0 {
  gap: 0;
}

.px-5 {
  padding-left: 4px;
  padding-right: 4px;
}

.py-5 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.mx-32 {
  margin-left: 32px;
  margin-right: 32px;
}

.border-circle {
  border-radius: 50%;
}

.bgWhite {
  background-color: $color-white;
}

.paddingNone {
  padding: 0;
}

.mt-32 {
  margin-top: 32px;
}

.borderNone {
  border: none;
}

.boxShadowNone {
  box-shadow: none;
}

.border-gray-200 {
  border-bottom: 1px solid $color-gray-200
}

.mb-120 {
  margin-bottom: 120px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.cursorPointer {
  cursor: pointer;
}

.color-red-900 {
  color: $color-error-900;
}

.mb-none {
  margin-bottom: 0;
}

.no-BoxShadow {
  box-shadow: none;
}

.maxWidth-900 {
  max-width: 900px;
}

.right-0 {
  right: 0;
}

.absolute-center {
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}

.mapboxgl-canvas {
  width: 100% !important;
}

.no-scrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.w-fit {
  width: fit-content;
}

.h-auto {
  height: auto;
}

.min-w-half {
  min-width: 50%;
}

.border-gray {
  border: 1px solid $color-gray-200
}

.text-decoration-none {
  text-decoration: none;
}

.positionStatic {
  position: static;
}

.cursor-pointer {
  cursor: pointer;
}

.overflowVisible {
  overflow: visible;
}

.h-fit {
  height: fit-content;
}

.color-gray-25 {
  color: $color-gray-25;
}

.grid-rows-auto {
  grid-auto-rows: min-content;
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.max-w-320 {
  max-width: 320px;
}

.max-w-full {
  max-width: 100%;
}

.display-none {
  display: none;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.z1000 {
  z-index: 1000;
}

.positionFixed {
  position: fixed;
}

.bottom0 {
  bottom: 0;
}

.ml-auto {
  margin-left: auto;
}

.z10001 {
  z-index: 10001;
}

.textAlignLeft {
  text-align: left;
}

.mt-13px {
  margin-top: 13px;
}

.hoverNone {
  &:hover {
    background: none;
  }
}

.justifyEnd {
  justify-content: end;
}

.color-gray-800 {
  color: $color-gray-800;
}

.color-black {
  color: #000000;
}