.dropDownSelect {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 100%;
  max-height: 310px;
  height: max-content;
  overflow-y: scroll;
  padding: 0px 18px;
  box-sizing: border-box;

  &-heightLg {
    max-height: 80%;
    box-sizing: border-box;
    
    &-hasSelected {
      max-height: 70%;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $color-white; 
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-gray-200;
    border-radius: 8px;
    height: 50%;
  }

  &-label {
    color: $color-gray-600;
    @extend .text-sm;
    @extend .font-medium;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    width: 100%;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 10px 10px 14px;
      box-sizing: border-box;
      cursor: pointer;

      &-info {
        display: flex;
        align-items: center;
        gap: 12px;

        &-text {
          display: flex;
          flex-direction: column;

          &-title {
            color: $color-gray-900;
            @extend .text-sm;
            @extend .font-medium;
          }

          &-description {
            color: $color-gray-600;
            @extend .text-sm;
            @extend .font-regular;
          }
        }
      }
    }
  }
}