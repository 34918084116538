.form {
  display: inline-flex;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  & p {
    color: $color-gray-600;
  }

  &_error p {
    color: $color-error-500;
  }
}

.formNew {
  display: flex;
  padding: 10px 12px;
  align-items: start;
  justify-content: end;
  box-sizing: border-box;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid $color-gray-600;
  background: $color-gray-50;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $color-gray-900;
  width: 100%;
  height: 78px;
  flex-direction: column;
  position: relative;

  &-textArea {
    height: 125px;
  }


  &:focus-within {
    & label {
      transform: translate(0, 10px) scale(1) !important;

      @extend .text-sm, .font-medium;
    }

    & div.inputWrapper {
      height: fit-content;
      margin-top: auto;

      & input {
        height: fit-content !important;
      }
    }
  }

  &-sm {
    height: 52px;

    &-filled {
      padding-bottom: 5px;
    }

    &:focus-within {
      padding-bottom: 5px;
      & label {
        transform: translate(0, 4px) scale(1) !important;
  
        @extend .text-xs, .font-regular;
      }
    }
  }

  & label {
    transition: transform 0.2s;
    position: absolute;
    top: 0;
  }

  &-placeholder {
    color: $color-gray-600;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    transform: translate(0, 24px) scale(1) !important;
    pointer-events: none;

    &-sm {
      transform: translate(30px, 11px) scale(1) !important;
    }

    &-textArea-old {
      transform: translate(12px, 40px) scale(1) !important;
    }

    &-search {
      transform: translate(30px, 24px) scale(1) !important;

      &-sm {
        transform: translate(30px, 11px) scale(1) !important;
      }

      &-old {
        transform: translate(50px, 48px) scale(1) !important;
      }
    }
  }

  &-label {
    transform: translate(0, 10px) scale(1);

    @extend .text-sm, .font-medium;

    &-sm {
      transform: translate(0, 4px) scale(1);

      @extend .text-xs, .font-regular;
    }
  }

  &-paddingLg {
    padding: 24px 12px;
  }
}

.input {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid $color-gray-300;
  background: $color-white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $color-gray-900;
  width: 100%;

  &:focus-within {
    box-shadow: 0px 0px 0px 4px #E0EAFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-color: $color-primary-300;
  }

  &:focus-visible {
    outline: none;
  }

  &-disabled {
    pointer-events: none;
    border-color: $color-gray-300;
    background-color: $color-gray-50;
    color: $color-gray-500;
  }

  &-error {
    border-color: $color-error-300 !important;

    &:focus-within {
      box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border-color: $color-error-300;
    }
  }

  &-search {
    width: 100%;

    &-primary {
      border: 1px solid $color-primary-600;
      background: $color-primary-600;
      
      & input {
        color: $color-primary-200;
        @extend .font-regular;

        &::placeholder {
          color: $color-primary-200;
        }
      }

      &:focus-within {
        box-shadow: none;
        border: 1px solid $color-primary-600;
      }
    }

    &-gray {
      border: 1px solid $color-gray-300;
      background: $color-white;
      
      & input {
        color: $color-gray-900;
        @extend .font-medium;
        width: 100%;
        overflow-x: scroll;
      
        &::placeholder {
          @extend .font-regular;
          color: $color-gray-500;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-dark {
      border: 1px solid $color-gray-600;
      background: $color-gray-800;
      
      & input {
        color: $color-gray-200;
        @extend .font-medium;
        width: 100%;
        overflow-x: scroll;
      
        &::placeholder {
          @extend .font-regular;
          color: $color-gray-200;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

.border-input {
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 16px;

  &:focus-within {
    box-shadow: none;
  }

  &-right {
    border-right: 1px solid $color-gray-300;
  }

  &-left {
    border-left: 1px solid $color-gray-300;
  }

  &-top {
    border-top: 1px solid $color-gray-300;
  }

  &-bottom {
    border-bottom: 1px solid $color-gray-300;
  }
}

.inputWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  height: 100%;

  &-filled {
    height: fit-content;
    margin-top: auto;

    & input {
      height: fit-content !important;
    }
  }
}

.input input {
  outline: none;
  border: none;
  background-color: inherit;
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.websiteInputWrapper {
  display: flex;
  align-items: center;
}

.websiteInput {
  &-left {
    border-radius: 8px 8px 8px 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid $color-gray-300;
    width: 100%;
  }

  &-right {
    border-radius: 8px 0 8px 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid $color-gray-300;
    width: 100%;
  }

  &-error {
    border-color: $color-error-300;

    &:focus-within {
      box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border-color: $color-error-300;
    }
  }
}

.websitePathStart {
  &-disabled {
    pointer-events: none;
    border-color: $color-gray-300;
    background-color: $color-gray-50;
    color: $color-gray-600;
  }
}

p[class~="websitePathStart"] {
  padding: 8px 12px;
  color: $color-gray-600;
  border: 1px solid $color-gray-300;
  border-right: none;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.websiteCopyFeatButton {
  display: inline-flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: $color-white;
  color: $color-gray-700;
  border: 1px solid $color-gray-300;
  border-left: none;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cardIcon {
  padding: 5px 6px;
  border: 1px solid $color-gray-100;
  border-radius: 4px;
  background-color: $color-white;
}

.input .dollarSign {
  color: $color-gray-600
}

.copyButton {
  color: $color-gray-700;
  cursor: pointer;
}

.cardsInInputWrapper {
  display: flex;
  align-items: center;
  max-width: 70%;
  overflow-x: scroll;
  gap: 6px;
  
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.cardInInput {
  display: flex;
  flex-wrap: nowrap;
  padding: 2px 4px 2px 5px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 24px;
  border: 1px solid $color-gray-300;
  background: $color-white;
}

.textArea {
  display: inline-flex;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 8px;
  border: 1px solid $color-gray-300;
  background: $color-white;
  width: 100%;
  height: 125px;
  box-sizing: border-box;
  padding: 12px 14px;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }

  & h4 {
    color: $color-gray-700;
  }

  & input {
    display: inline-flex;
    font-style: normal;
    font-family: inherit;
    overflow-x: hidden;
    border: none;
    outline: none;
    width: fit-content;
  }

  & textarea {
    resize: none;
    border: none;
    outline: none;
    background-color: inherit;
    min-height: 100%;
    width: 100%;
    font-style: normal;
    font-family: inherit;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & div {
    width: max-content;
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
  }

  &:focus-within {
    box-shadow: 0px 0px 0px 4px #E0EAFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-color: $color-primary-300;
  }

  &-disabled {
    border-color: $color-gray-300;
    background: $color-gray-50;
  }

  &-error {
    border-color: $color-error-300;

    &:focus-within {
      box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border-color: $color-error-300;
    }
  }
}

.textAreaNew {
  display: inline-flex;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 12px;
  border: 1px solid $color-gray-600;
  background: $color-gray-50;
  width: 100%;
  height: 125px;
  box-sizing: border-box;
  padding: 24px 12px;
  position: relative;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }

  &-filled {
    background: none;
    border: none;
    padding: 0;
    box-shadow: none;
    height: 80px;
  }

  & textarea {
    resize: none;
    border: none;
    outline: none;
    background-color: inherit;
    min-height: 100%;
    width: 100%;
    font-style: normal;
    font-family: inherit;

    &::-webkit-scrollbar {
      display: none;
    }

    &::placeholder {
      color: $color-gray-600;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
    }
    
    &:focus-visible {
      outline: none;
    }
  }
}

.inputNew {
  display: flex;
  padding: 24px 12px;
  align-items: end;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid $color-gray-600;
  background: $color-gray-50;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $color-gray-900;
  width: 100%;
  position: relative;
  height: 100%;

  &-error {
    border: 1px solid $color-error-600;

    &-text {
      color: $color-error-600;

      @extend .text-xs, .font-medium;
    }
  }


  &:focus-visible {
    outline: none;
  }

  &-filled {
    background: none;
    border: none;
    padding: 0;
    box-shadow: none;

    &:focus-visible {
      outline: none;
    }
  }

  & div {
    & input {
      background-color: inherit;
      border: none;
      width: 100%;
      height: 100%;
  
      &::placeholder {
        color: $color-gray-600;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }
  
      &:focus-visible {
        outline: none;
      }
    }
  }
}

.showPasswordIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  transition: transform 0.3s;

  &-true {
    position: static;
    transform: translateY(0);
  }
}