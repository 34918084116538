.day {
  position: relative;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $color-white;
  border-radius: 20px;
  border: none;
  cursor: pointer;

  &:hover {
    background: $color-gray-50;
  }

  &:focus {
    outline: none;
  }

  &-today {
    background-color: $color-gray-100;

    &:hover {
      background-color: $color-gray-100;
    }
  }

  &-disabled {
    pointer-events: none;
    color: $color-gray-500;
  }

  &-inCurrentMonth {
    color: $color-gray-700;
  }

  &-picked {
    background: $color-primary-600;
    color: $color-white;

    &:hover {
      background-color: $color-primary-600;
    }
  }

  &-withEvent {
    &-circle {
      position: absolute;
      bottom: 4px;
      right: 50%;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 5px;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: $color-primary-600;

      &-picked {
        background-color: $color-white;
      }

      &-disabled {
        pointer-events: none;
        background-color: $color-gray-400;
      }
    }
  }
}

.calendar {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  z-index: 50;
}

.largeDatePicker {
  display: none;
  width: max-content;
  border-radius: 8px;
  border: 1px solid $color-gray-100;
  background: $color-white;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  position: relative;
  min-height: max-content;
  z-index: 30;

  &-open {
    position: absolute;
    display: flex;
  }

  &-modal {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
}

.modal {
  background-color: rgba(16, 24, 40, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
}

.dayPicker {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-right: 1px solid $color-gray-200;

  &-button {
    background-color: inherit;
    outline: none;
    border: none;
    cursor: pointer;
    @extend .text-sm;
    @extend .font-medium;
    text-align: start;
    width: 160px;
    padding: 10px 16px;

    &-selected {
      border-radius: 6px;
      background: $color-gray-50; 
    }
  }
}

.datePickersContainer {
  display: flex;
  border-bottom: 1px solid $color-gray-200;
}

.datePicker {
  padding: 0 24px;

  &-first {
    border-right: 1px solid $color-gray-100;
  }
}

.datePicker-footer {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 32px
}

.datePickerMinus {
  @extend .text-md;
  @extend .font-regular;
  color: $color-gray-500;
}

.calendarOpenButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.openButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  @extend .text-md;
  @extend .font-semibold;

  &-color-gray {
    color: $color-gray-500;
  }

  &-width-content {
    width: max-content;
  }

  & p {
    white-space: nowrap;
  }
}

.calendarDates {
  position: absolute;
  top: 58px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
  box-sizing: border-box;
}

.calendarDateInfo {
  padding: 10px 14px;
  white-space: nowrap;
  @extend .text-sm;
  @extend .font-regular;

  &-lg {
    width: 200px;
  }
}