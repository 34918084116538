.teamsDropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 320px;
  overflow: scroll;
  border-radius: 8px;
  border: 1px solid $color-gray-200;
  background: $color-white;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);


  &::-webkit-scrollbar {
    display: none;
  }
  
  &-item {
    display: flex;
    padding: 12px 16px 12px 14px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    background: $color-white;
    cursor: pointer;
    margin: 4px;
    border-radius: 6px;
    cursor: pointer;

    &-name {
      color: var(--Gray-900, #101828);
      list-style: none;
      @extend .text-md;
      @extend .font-medium;
    }

    &-selected {
      background-color: $color-primary-50;
    }
  }
}