.reviewContactDetails {
  display: flex;
  padding: 8px 16px 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-left: 1px solid $color-gray-200;
  background: $color-gray-25;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media (min-width: 768px) {
    position: relative;
    width: 55%;
  }

  &-eventsDropdown {
    position: absolute;
    top: 28%;
    left: 5%;
    width: 90%;
    box-sizing: border-box;
    background: $color-white;
    border: 1px solid $color-gray-200;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    max-height: 250px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &-actions {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border-top: 1px solid $color-gray-200;
      box-sizing: border-box;
      gap: 4px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px;
      box-sizing: border-box;

      &:hover {
        background-color: $color-gray-100;
      }

      &-info {
        display: flex;
        gap: 16px;
        align-items: center;
      }
    }
  }

  &-header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      color: $color-gray-900;

      @extend .text-xl;
      @extend .font-semibold;
    }

    &-actions {
      display: flex;
      gap: 2px;
      align-items: center;
    }
  }

  &-userInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap: 4px;
    align-items: flex-start;

    &-name {
      color: $color-gray-900;

      @extend .xs;
      @extend .font-semibold;
    }

    &-email {
      color: $color-gray-600;

      @extend .text-md;
      @extend .font-regular;
    }
  }

  &-userEvents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;

    &-title {
      color: $color-gray-700;

      @extend .text-sm;
      @extend .font-medium;
    }

    &-event {
      display: flex;
      align-items: flex-start;
      gap: 12px;

      &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      &-header {
        display: flex;
        width: 100%;
        justify-content: space-between;

        &-name {
          color: $color-gray-700;

          @extend .text-sm;
          @extend .font-medium;
        }

        &-rsvp {
          display: flex;
          padding: 2px 8px;
          align-items: center;

          &-yes {
            color: $color-success-600;
            border-radius: 16px;
            border: 1px solid $color-success-200;
            background: $color-success-50;

            @extend .text-xs;
            @extend .font-medium;
          }

          &-no {
            color: $color-error-700;
            border-radius: 16px;
            border: 1px solid $color-error-200;
            background: $color-error-50;

            @extend .text-xs;
            @extend .font-medium;
          }

          &-maybe {
            color: $color-gray-700;
            border-radius: 16px;
            border: 1px solid $color-gray-200;
            background: $color-gray-50;

            @extend .text-xs;
            @extend .font-medium;
          }

          &-pending {
            color: $color-warning-700;
            border-radius: 16px;
            border: 1px solid $color-warning-200;
            background: $color-warning-50;

            @extend .text-xs;
            @extend .font-medium;
          }
        }
      }

      &-description {
        color: $color-gray-600;

        @extend .text-sm;
        @extend .font-regular;
      }

      &-time {
        color: $color-gray-600;

        @extend .text-sm;
        @extend .font-regular;
      }
    }
  }

  &-userDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;

    &-item {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 2px solid $color-gray-200;

      &-description {
        color: $color-gray-500;

        @extend .text-sm;
        @extend .font-medium;
      }

      &-title {
        color: $color-gray-700;

        @extend .text-sm;
        @extend .font-medium;
      }
    }
  }
}