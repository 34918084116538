.reviewEventDetailsPage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: scroll;
  height: 100%;
  background: transparent;
  margin-bottom: 50px;

  &::-webkit-scrollbar {
    display: none;
  }

  &-actions {
    border: 1px solid $color-gray-200;
    background-color: $color-white;
    padding: 12px;
    gap: 4px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;

    &-direction {
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      &-directionMap {
        width: 100%;
        height: 90px;
        border-radius: 12px;
        overflow: hidden;
      }
    }
  }

  @media (min-width: 768px) {
    width: 75%;
    margin: 0 auto;
  }

  &-fixedHeader {
    position: fixed;
    top: 0;
    box-sizing: border-box;
    z-index: 101;
    display: flex;
    width: 100%;
    padding: 24px 12px;
    background: $color-white;
    border-bottom: 1px solid $color-gray-200;
    align-items: center;
    justify-content: space-between;

    &-fixed {
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    }

    & img {
      cursor: pointer;
    }

    &-attendeeView {
      background-color: $color-primary-600;
    }
  }

  &-opacityBackground {
    height: 100%;
    width: 100%;
    opacity: 0.5;
    background: $color-gray-900;
    position: absolute;
    top: 0;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;

    &-title {
      &-lg {
        color: $color-gray-900;

        @extend .sm;
        @extend .font-semibold;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      position: relative;
      z-index: 40;
      transition: 2s margin-top;
      padding: 12px;
      border: 1px solid $color-gray-200;
      border-radius: 12px;
      box-sizing: border-box;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }

    &-mainImage {
      z-index: 30;
      display: flex;
      box-sizing: border-box;
      min-width: 96px;
      width: 96px;
      height: 96px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $color-primary-600;
      border: 4px solid $color-white;
      transition: width 1s;
      transition: height 1s;

      &-success {
        background: $color-success-500;

        &:hover {
          background: $color-success-700;
        }
      }
    
      &-yellow {
        background: $color-yellow-400;

        &:hover {
          background: $color-yellow-700;
        }
      }
    
      &-warning {
        background: $color-warning-500;

        &:hover {
          background: $color-warning-700;
        }
      }
    
      &-error {
        background: $color-error-500;

        &:hover {
          background: $color-error-700;
        }
      }
    
      &-purple {
        background: $color-purple-500;

        &:hover {
          background: $color-purple-700;
        }
      }
    
      &-blueLight {
        background: $color-blueLight-500;
        
        &:hover {
          background: $color-blueLight-700;
        }
      }

      &-pastEvent {
        background-color: $color-gray-600;

        &:hover {
          background: $color-gray-700;
        }
      }

      @media (min-width: 768px) {
        min-width: 160px;
        width: 160px;
        height: 160px;

        & img {
          min-width: 80px;
          width: 80px;
          height: 80px;
        }
      }

      &-sm {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border: none;

        & img {
          min-width: 20px;
          width: 20px;
          height: 20px;
        }
      }

      &-md {
        width: 48px;
        min-width: 48px;
        height: 48px;
        border: none;

        & img {
          min-width: 28px;
          width: 28px;
          height: 28px;
        }
      }

      &-lg {
        width: 96px;
        min-width: 96px;
        height: 96px;
        transition: height, width 1s;

        & img {
          min-width: 48px;
          width: 48px;
          height: 48px;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      @media (min-width: 768px) {
        width: 80%;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        &-title {
          color: $color-gray-900;

          @extend .sm;
          @extend .font-semibold;
        }

        &-description {
          color: $color-gray-600;

          @extend .text-md;
          @extend .font-regular;
        }
      }

      &-actions {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

    &-path {
      display: flex;
      align-items: center;
      gap: 8px;

      &-arrow {
        color: $color-gray-300;

        @extend .text-sm;
        @extend .font-medium;
      }

      &-step {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        color: $color-gray-600;
        cursor: pointer;

        @extend .text-sm;
        @extend .font-medium;

        &-current {
          border-radius: 6px;
          background: $color-gray-50;
          color: $color-gray-700;
          cursor: auto;

          @extend .font-semibold;
        }
      }
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;

    &-scrolled {
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    }

    &-attachDoc {
      width: 32px;
      height: 32px;
      padding: 8px;
      gap: 10px;
      border-radius: 4px;
      box-sizing: border-box;
      background-color: $color-primary-100;
      cursor: pointer;
    }

    &-attendingInfoBlock {
      padding: 12px;
      border-radius: 12px;
      background-color: $color-violet-200;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-label {
          color: $color-gray-950;

          @extend .text-xs;
          @extend .font-regular;
        }

        &-img {
          width: 16px;
          min-width: 16px;
          height: 16px;
          min-height: 16px;
        }
      }

      &-title {
        color: $color-gray-950;

        @extend .xl;
        @extend .font-bold;
      }
    }

    &-content {
      display: flex;
      gap: 16px;
      width: 100%;
      box-sizing: border-box;
      height: 100%;
      margin-top: 70px;
      flex-direction: column;
      padding: 0 12px;
      background-color: transparent;
      
      @media (min-width: 768px) {
        background-color: $color-white;
      }

      @media (min-width: 768px) {
        flex-direction: row;
        margin-right: 32px;
        margin-left: 32px;
        margin-top: 300px;
        justify-content: space-between;
        padding: 0;
        width: calc(100% - 64px);
        background: none;
      }
    }
  }

  &-footer {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    z-index: 100;
    display: flex;
    width: 100%;
    padding: 24px 12px;
    background: $color-white;
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.12);
  }
}

.reviewAgenda {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 20px 12px;
  border: 1px solid $color-gray-200;
  border-radius: 12px;

  &-title {
    color: $color-gray-950;

    @extend .text-lg;
    @extend .font-semibold;
  }
}