.reviewAgendaSection { 
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
    
  &-info {
    width: 100%;

    @media (min-width: 768px) {
      width: 35%;
    }
  }

  &-blocks {
    display: flex;
    align-items: center;
    gap: 8px; 

    &-close {
      cursor: pointer;
    }

    &-block {
      display: flex;
      padding: 4px 12px 4px 10px;
      align-items: center;
      gap: 4px;
      border-radius: 16px;

      &-meal {
        border: 1px solid $color-warning-200;
        background: $color-warning-50;

        &-title {
          color: $color-error-700;

          @extend .text-sm;
          @extend .font-medium;
        }
      }

      &-session {
        border: 1px solid $color-primary-200;
        background: $color-primary-50;

        &-title {
          color: $color-primary-700;

          @extend .text-sm;
          @extend .font-medium;
        }
      }

      &-break {
        border: 1px solid $color-gray-200;
        background: $color-gray-50;

        &-title {
          color: $color-gray-700;

          @extend .text-sm;
          @extend .font-medium;
        }
      }
    }
  }
}

.reviewAgendaModalBlock {
  @media (min-width: 768px) {
    position: relative;
  }

  &-timeInfo {
    @media (min-width: 768px) {
      position: absolute;
    }  
  }

  &-info {
    @media (min-width: 768px) {
      margin-left: 124px;
    }  
  }
}