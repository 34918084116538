.toggleButtons {
  display: flex;
  align-items: center;
  min-width: min-content;
  flex-shrink: 1;

  &-notSelected {
    background-color: $color-white;

    &-gray {
      background-color: $color-gray-200;
    }
  }

  &-first {
    height: 36px;
    border-radius: 40px 0px 0px 40px;
    width: 50%;
    padding: 8px 35px;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-gray-200;
    box-sizing: border-box;
    cursor: pointer;

    & img {
      height: 20px;
      width: 20px;
    }
  }

  &-second {
    height: 36px;
    width: 50%;
    border-radius: 0px 40px 40px 0px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 4px;
    justify-content: center;
    border: 1px solid $color-gray-200;
    box-sizing: border-box;
    cursor: pointer;

    & img {
      height: 20px;
      width: 20px;
    }
  }

  &-selected {
    background-color: $color-primary-600;
    color: $color-white;
  }
}