.reviewDetailsSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  z-index: 30;

  @media (min-width: 768px) {
    width: 30%;
  }

  &-button-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
  }

  &-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &-title {
      color: $color-gray-900;
      @extend .text-lg;
      @extend .font-semibold;
    }

    &-dots {
      cursor: pointer;
    }
  }

  &-content {
    display: flex;
    padding: 0 0 24px;
    flex-direction: column;
    gap: 24px;
    border-bottom: 1px solid $color-gray-200;
    background: $color-white;

    @media (min-width: 768px) {
      border-bottom: none;
    }

    &-about {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-info {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-title {
          color: $color-gray-900;
          @extend .text-md;
          @extend .font-medium;
        }

        &-description {
          color: $color-gray-600;
          @extend .text-md;
          @extend .font-regular;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media (min-width: 768px) {
        gap: 24px;
        justify-content: space-between;
      }
    
      &-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow: hidden;
    
        &-title {
          color: $color-gray-500;
          @extend .text-sm;
          @extend .font-medium;
        }
      }
    }
  }
}