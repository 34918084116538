.myDetails {
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: $color-gray-50;

  &-info {
    width: 100%;

    &-title {
      color: $color-gray-700;

      @extend .text-sm;
      @extend .font-semibold;
    }

    &-description {
      color: $color-gray-600;

      @extend .text-sm;
      @extend .font-regular;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }

  &-content {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid $color-gray-200;
    background: $color-white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 100%;
    height: max-content;
    box-sizing: border-box;

    &-dropIcon {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      width: 100%;


      &-icon {
        display: flex;
        width: 64px;
        height: 64px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 200px;
      }
    }

    &-container {
      width: 100%;
      gap: 24px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }

    &-name {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    border-top: 1px solid $color-gray-200;
    padding: 16px 24px;
  }
}

.settingsBackColor {
  background: $color-gray-50;

  @media (min-width: 768px) {
    background: $color-white;
  }
}

.settingsBilling {
  width: 100%;
  border-radius: 12px;
  background: $color-primary-600;
  border: 1px solid $color-gray-300;
  padding: 47.5px 24px; 
  box-sizing: border-box;
  background-size: cover;
  background-position: 10%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &-title {
    color: $color-gray-25;

    @extend .text-lg;
    @extend .font-semibold;
  }

  &-description {
    color: $color-gray-25;

    @extend .text-sm;
    @extend .font-regular;
  }
}