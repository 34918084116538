.login {
  display: flex;
  height: max-content;
  width: 100%;
  gap: 10px;
  box-sizing: border-box;

  &-signIn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    padding: 0;
    gap: 32px;

    @media (min-width: 768px) {
      width: 40%;
      padding: 32px;
      align-items: center;
    }

    &-title {
      color: $color-gray-900;
      text-align: start;
      @extend .sm;
      @extend .font-semibold;
    }

    &-description {
      color: $color-gray-600;
      text-align: center;
      @extend .text-md;
      @extend .font-regular;
    }

    &-copyright {
      color: $color-gray-600;
      text-align: center;
      @extend .text-sm;
      @extend .font-regular;

      margin: 0 auto;

      @media (min-width: 768px) {
        margin: 0;
      }
    }

    &-copy {
      color: $color-white;
      text-align: center;
      @extend .md;
      @extend .font-medium;
      white-space: nowrap;

      position: absolute;
      bottom: 56px;
      left: 56px;
    }

    &-logo {
      width: 142px;
      margin: 0 auto;

      @media (min-width: 768px) {
        margin: 0;
      }
    }

    &-header {
      width: 100%;
      border-bottom: 1px solid var(--gray-200, #EAECF0);
      background: var(--base-white, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
      padding: 12px 8px 12px 16px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      box-sizing: border-box;

      @media (min-width: 768px) {
        padding: 0;
        align-items: flex-start;
        border-bottom: none;
        box-shadow: none;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 16px;
      gap: 32px;

      @media (min-width: 768px) {
        padding: 0;
        align-items: center;
        max-width: 80%;
        min-width: 80%;
      }
    }
  }

  &-bgImage {
    background-image: url('../../assets/images/loginBgImage.jfif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 80px 0px 0px 80px;
    width: 60%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
}