.pagination {
  display: flex;
  padding: 12px 24px 16px 24px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  &-numbers {
    display: flex;
    gap: 2px;

    &-number {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;

      &-notNumber {
        cursor: default;
      }

      &-selected {
        background-color: $color-gray-50;
      }
    }
  }
}