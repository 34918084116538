.dropdown {
  display: flex;
  align-items: center;
  align-self: stretch;

  &-withBorder {
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid $color-gray-300;
    background: $color-white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  & h1 {
    color: $color-gray-900;
  }

  &-content {
    position: absolute;
    top: 75px;
    display: inline-block;
    height: max-content;
    max-height: 320px;
    width: 100%;
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    border-radius: 8px;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    box-sizing: border-box;
    padding: 4px 4px;
    z-index: 30;
  }
}

.menuDropdown {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.5s;

  &:hover {
    background-color: $color-primary-800;
    border-radius: 6px;
  }

  &-gray {
    &:hover {
      background-color: $color-gray-100;
      border-radius: 6px;
    }
  }

  &-dropdown {
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  &-primary-selected {
    border-radius: 6px;
    background-color: $color-primary-600;
  }

  &-gray-selected {
    border-radius: 6px;
    background-color: $color-gray-100;
  }

  &_content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &_buttons {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    box-sizing: border-box;
  }

  &-title {
    @extend .text-md;
    @extend .font-semibold;

    &-primary {
      color: $color-primary-100;
    }

    &-default {
      color: $color-gray-700;
    }

    &-dark {
      color: $color-gray-100;
    }
  }
}

.menuDropdownNew {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border 0.5s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: $color-gray-950;
  @extend .text-sm;
  @extend .font-semibold;

  &:hover {
    background-color: $color-gray-50;
    border-radius: 12px;
  }

  &-withDropdown {
    &::after {
      content: '';
      position: absolute;
      height: calc(100% - 8px);
      top: 50%;
      bottom: 50%;
      transform: translate(-50%, -50%);
      // background-color: $color-primary-600;
      border-right: 3px solid $color-primary-600;
      right: -16px;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }

  &-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -210px;
    top: 0;
    background-color: $color-white;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    padding: 8px;
    gap: 16px;
    border-radius: 8px;
    z-index: 1000;

    &-item {
      width: 100%;
      padding: 8px 12px;
      gap: 8px;
      border-radius: 8px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 8px;

      &-image {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: $color-gray-50;
      }

      &-selected {
        background-color: $color-gray-50;
      }
    }
  }

  &-primary-selected {
    border-radius: 12px;
    background-color: $color-primary-600;
    color: $color-white;

    &:hover {
      background-color: $color-primary-700;
      border-radius: 12px;
    }
  }

  &-logo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $color-primary-50;
    padding: 0;

    @media (min-width: 768px) {
      border-radius: 12px;
      width: 100%;
      height: auto;
      padding: inherit;
    }

    &:hover {
      background-color: $color-primary-50;
    }

    & img {
      width: 90%;
      height: 60%;
      rotate: -12deg;
    }
  }

  &_content {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &_buttons {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    box-sizing: border-box;
  }

  &-title {
    @extend .text-sm;
    @extend .font-semibold;
  }
}