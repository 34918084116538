.selectMeals {
  position: absolute;
  z-index: 10001;
  bottom: -100%;
  width: 100%;
  height: 98%;
  display: flex;
  overflow: hidden;
  transition: all 1s ease-in-out;

  &-container {
    position: absolute;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    height: 0;
    display: flex;
    overflow: hidden;

    &-isOpen {
      height: 100%;
    }
  }

  @media (min-width: 768px) {
    bottom: 0;
    top: 0;
    right: -100%;
    width: 0;
    height: 100%;
  }

  &-isOpen {
    bottom: 0;

    @media (min-width: 768px) {
      right: 0;
      width: 90%;
    }
  }
  
  &-customizations {
    height: 80%;
    overflow: scroll;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    &-header {
      padding: 24px 24px 0 24px;
      box-sizing: border-box;
    }

    &-main {
      padding: 0px 24px 0 24px;
      box-sizing: border-box;
      margin-bottom: 0;
    }

    &-close {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    &-actions {
      width: 100%;
      position: sticky;
      right: 0;
      bottom: 0;
      background-color: $color-white;
      padding: 24px;
      box-sizing: border-box;
      transition: box-shadow 0.7s;
      
      &-notScrolledToBottom {
        box-shadow: 0px -4px 8px -2px rgba(16, 24, 40, 0.1);
      }
    }
  }
}